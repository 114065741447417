import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const StoreDetail = (props) => {
  const {storeName, userName, storeID} = props;

  return (
    <div className="store-detail">
      <div className="store-detail__wrapper">
        <Link to={`/account/local-itm/?id=${storeID}`} className="store-detail__store-name"><h1><i className="fas fa-map-marker-alt"></i>{storeName}</h1></Link>
        <h2 className="store-detail__user-name">kia ora, {userName}!</h2>
      </div>
    </div>
  );
};

StoreDetail.defaultProps = {
  storeName: null,
  userName: null,
  storeID: 0
};

StoreDetail.propTypes = {
  storeName: PropTypes.string,
  userName: PropTypes.string,
  storeID: PropTypes.number
};

export default StoreDetail;