import React, {useEffect} from 'react';
import HeadPage from '../HeadPage';
import Button from '../Form/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {userSignupStageUpdate} from '../../actions/SignupActions';

const CompleteSignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect( () => {
    history.replace('/sign-up');
  }, []);

  const handleContinueClick = () => {
    dispatch(userSignupStageUpdate('customer-type'));
    history.push('/sign-up/customer-type');
  };

  const user = useSelector( (state) => state.auth.user);
  const welcome = `Welcome ${user.firstName}. Nearly there, we just need some more information...`;

  return (
    <>
      <HeadPage
        pageTitle="Nice job!"
        titleBgColor="white"
        pageDescription={welcome}
      />
      <div className="auth-success horizontal-spacing">
        <p>What&apos;s next.</p>
        <div className="auth-success__next-steps ">
          <ul>
            <li>
              <span>1</span>
              <h4>Select Account Type</h4>
            </li>
            <li>
              <span>2</span>
              <h4>Select your local ITM</h4>
            </li>
          </ul>
          <div className="sticky-bottom">
            <Button
              text="Continue Setup"
              handleSubmit={handleContinueClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CompleteSignUp.propTypes = {};

CompleteSignUp.defaultProps = {};

export default CompleteSignUp;
