import { getAuthenticatedAxios, withErrorHandler } from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const PostStoreDetails = async (storeID) => {
  const payload = {
    Store: {
      NodeID: storeID,
    },
  };

  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      payload,
    ),
  );

  return data;    
};
