import React, {useEffect} from 'react';
import Button from '../Form/Button';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { logout } from '../../services/Msal';

const SignOut = () => {
  const isLoggedIn = useIsAuthenticated();
  const history = useHistory();
  const {inProgress} = useMsal();

  const onSignoutClick = () => {
    logout();
  };

  /* Already logged out, then redirect to session end page */
  useEffect( () => {
    if (!isLoggedIn && inProgress == InteractionStatus.None) {
      history.push('/logged-out');
    }
  }, [isLoggedIn, inProgress]);

  return (
    <>
      <div>
        <Button text="Sign out" type="primary" handleSubmit={onSignoutClick}/>
      </div>
    </>
  );
};

SignOut.propTypes = {
};

SignOut.defaultProps = {
};


export default SignOut;