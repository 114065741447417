import React from 'react';
import PropTypes from 'prop-types';

const LbpActivityLogAdd = (props) => {
    const {title, description, handleClick, isDisabled} = props;
    return (
        <div className="activity-log-add">
          <div className="activity-log-add__wrapper">
            <h5 className="activity-log-add__title">{title}</h5>
            <p className="activity-log-add__description">{description}</p>
            <button className={'activity-log-add__btn'} disabled={isDisabled} onClick={() => {handleClick();}}>Add to LBP Activity Log</button>
          </div>
        </div>
    );
};

LbpActivityLogAdd.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    handleClick: PropTypes.func,
    isDisabled: PropTypes.bool,
};
  
LbpActivityLogAdd.defaultProps = {
    title: '',
    description: '',
    handleClick: () => {},
    isDisabled: true,
};
  
export default LbpActivityLogAdd;