import {
  getAuthenticatedAxios,
  withErrorHandler,
} from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const PostLbpUserProfile = async (profile) => {
  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      profile,
    ),
  );

  return data;
};

export const PostLbpAddActivity = async (activities) => {
  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/lbp/activities/post'),
      activities,
    ),
  );

  return data;
};
