import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadFavouritesAndRecentlyViewed } from '../../actions/DocumentsLibraryActions';
import {postDocumentsLibraryFavourites} from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibraryCard from '../../components/DocumentsLibrary/DocumentLibraryCard';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const DocumentFavourites = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFavouritesAndRecentlyViewed());
    pageViewGA('Document Favourites');
  }, [dispatch]);

  const { mediaFavourites } = useSelector((state) => state.documents);

  const handleSelectFavourite = (id) => {
    dispatch(postDocumentsLibraryFavourites(id));
  };  

  const cards = mediaFavourites.map((card) => {
    const props = {
        title: card.Title,
        id: card.NodeID,
        isFullWidth: true,
        linkUrl: card.Link,
        iconType: card.Type,
        isFavourite: true,
        handleSelectFavourite,
      };

      return <DocumentLibraryCard key={card.NodeID} {...props} />;   
  });

  return (
    <ActionPageLayout
      pageTitle='Favourites'
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <div className="cards-list cards-list--stack">{cards}</div>
    </ActionPageLayout>
  );
};

DocumentFavourites.propTypes = {};
DocumentFavourites.defaultProps = {};
