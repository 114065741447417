import React from 'react';
import PropTypes from 'prop-types';
import DocumentLibraryFeature from './DocumentLibraryFeature';
import {
  postDocumentsLibraryFavourites,
  postDocumentsLibraryRecentlyViewed,
} from '../../actions/DocumentsLibraryActions';
import { useDispatch } from 'react-redux';

const DocumentLibraryFeatures = ({
  categories,
  mediaFavourites,
  mediaRecentlyViewed,
}) => {
  const items = categories?.Items ? categories.Items : [];
  const dispatch = useDispatch();

  const handleSelectFavourite = (id) => {
    dispatch(postDocumentsLibraryFavourites(id));
  };

  const handleRecentlyViewed = (id, linkUrl) => {
    dispatch(postDocumentsLibraryRecentlyViewed(id, linkUrl));
  };

  return (
    <>
      <DocumentLibraryFeature
        title="Categories"
        type="Categories"
        cards={items}
        maxLength={5}
        seeAllUrl="/document-library/categories"
      />

      <DocumentLibraryFeature
        title="Favourites"
        type="Favourites"
        stack={true}
        cards={mediaFavourites}
        maxLength={3}
        seeAllUrl="/document-library/favourites"
        handleSelectFavourite={handleSelectFavourite}
        handleRecentlyViewed={handleRecentlyViewed}
        favourites={mediaFavourites}
      />

      <DocumentLibraryFeature
        title="Recently Viewed"
        type="Recently Viewed"
        stack={true}
        cards={mediaRecentlyViewed}
        maxLength={3}
        seeAllUrl="/document-library/recently-viewed"
        handleSelectFavourite={handleSelectFavourite}
        handleRecentlyViewed={handleRecentlyViewed}
        favourites={mediaFavourites}
      />
    </>
  );
};

DocumentLibraryFeatures.defaultProps = {
  categories: [],
  mediaFavourites: [],
  mediaRecentlyViewed: [],
};

DocumentLibraryFeatures.propTypes = {
  categories: PropTypes.array,
  mediaFavourites: PropTypes.array,
  mediaRecentlyViewed: PropTypes.array,
};

export default DocumentLibraryFeatures;
