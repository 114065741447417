import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewsPostsByUser } from '../../actions/NewsActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import ArticleBody from '../../components/News/NewsArticle';
import ArticleInfo from '../../components/ArticleInfo';
import ActionPageLayout from '../../layouts/ActionPageLayout';
import { Redirect } from 'react-router-dom';

export const NewsArticle = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNewsPostsByUser());
    pageViewGA('News articles');
    window.scrollTo({top: 0, left: 0});
  }, [dispatch]);

  const { newsPostsByUser, loading } = useSelector((state) => state.news);

  const items = newsPostsByUser?.Items || [];
  const url = new URL(window.location.href);
  const id = Number(url.searchParams.get('id'));
  const article = items.find((post) => post.NodeID === id);

  /* Article not found, go back to the home page */
  if (items.length > 0 && !article) {
    return <Redirect to="/" />;
  }

  return (
    <ActionPageLayout
      pageTitle={article?.ArticleName}
      loading={loading}
      titleBgColor="black"
    >
      <div className="article-page">
        <div className="article-page__wrapper">
          {article?.AuthorName && article?.PublishDate && (
            <ArticleInfo author={article?.AuthorName} publishedDate={article?.PublishDate} />
          )}
          <ArticleBody
            articleName={article?.ArticleName}
            articleImage={article?.HeroImage}
            articleTopContent={article?.TopContent}
            articleMiddleContent={article?.MiddleContent}
            articleBottomContent={article?.BottomContent}
            articleQuote={article?.ContentQuote}
          />
        </div>
      </div>
    </ActionPageLayout>
  );
};

ArticleBody.propTypes = {};
ArticleBody.defaultProps = {};
