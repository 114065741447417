import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  LbpActivitiesLog: [],
  LbpBasicsContent: {},
  LbpCodewords: {},
  LbpCodewordsLog: [],
};

export default function LbpReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_LBP_START:
      return {
        ...state,
        loading: action.loading,
      };

    case types.LOAD_LBP:
      return {
        ...state,
        ...action,
        loading: false,
      };

    case types.LOAD_LBP_BASICS:
      return {
        ...state,
        LbpBasicsContent: action.lbpBasicsContent,
      };

    case types.LOAD_LBP_ACTIVITIES_LOG:
      return {
        ...state,
        LbpActivitiesLog: action.activitiesLog,
      };

    case types.LOAD_LBP_CODEWORDS_LOG:
      return {
        ...state,
        LbpCodewordsLog: action.codewordsLog,
      };

    case types.LOAD_LBP_CODEWORDS:
      return {
        ...state,
        LbpCodewords: action.codewordsObj,
      };

    case types.LOAD_LBP_LICENCE_CLASSES:
      return {
        ...state,
        lbpLicenceClasses: action.lbpLicenceClasses,
      };

    case types.POST_LBP_ACTIVITY_SUCCESS:
      return {
        ...state,
        ...action.userProfileObj,
      };

    case types.POST_LBP_ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
}
