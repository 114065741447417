import * as React from 'react';

function HammerNailImage() {
  return (
    <div className="hammer-nail-image">
      <svg className="hammer-nail-image__top-icon" version="1.1" id="eW8N1ul6rPy1" textRendering="geometricPrecision" shapeRendering="geometricPrecision"
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 841.9 595.3"
        style={{ enableBackground: 'new 0 0 841.9 595.3' }} xmlSpace="preserve">
        <g id="eW8N1ul6rPy2" transform="matrix(33.073228 0 0 33.073186 -11003.369385 -6749.832323)">
          <g id="eW8N1ul6rPy3">
            <g id="eW8N1ul6rPy4" transform="matrix(0.703964 0.710236 -0.710236 0.703964 254.460391 -180.932921)">
              <path id="eW8N1ul6rPy5" className="st0" d="M343.6,210.2c0.1,0,0.3,0,0.4-0.1l0.3,8.2c0,0.8-0.6,1.5-1.5,1.5s-1.5-0.6-1.5-1.5
				l0.3-8.2c0.1,0,0.2,0.1,0.4,0.1H343.6z M347.3,206.1v3c0,0.1-0.2,0.4-0.4,0.4h-0.7c-0.1,0-0.4-0.2-0.4-0.4l0-0.4l-1,0
				c-0.3,0.4-0.8,0.8-1.3,0.7l-1.7,0c-0.4,0-0.8-0.2-1.1-0.5l-0.3-0.3l-2.3,0.7c0-2.1,1.6-3.7,3.7-3.7h1.6c0.6,0,1,0.3,1.3,0.7l1,0
				l0-0.4c0-0.1,0.2-0.4,0.4-0.4h0.7C347.1,205.7,347.3,205.9,347.3,206.1z"/>
            </g>
          </g>
        </g>
      </svg>
      <svg className="hammer-nail-image__bottom-icon" version="1.1" id="eW8N1ul6rPy1" textRendering="geometricPrecision" shapeRendering="geometricPrecision"
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 841.9 595.3"
        style={{ enableBackground: 'new 0 0 841.9 595.3' }} xmlSpace="preserve">
        <path id="eW8N1ul6rPy6" className="st0" d="M549.1,532.9V413.8h-6.6c-3.3,0-6.6-3.3-6.6-6.6v-16.5c0-3.3,3.3-6.6,6.6-6.6h62.8
   c3.3,0,6.6,3.3,6.6,6.6v16.5c0,3.3-3.3,6.6-6.6,6.6h-6.6v119.1"/>
        <path id="eW8N1ul6rPy7" className="st0" d="M704.6,556H234.9c-6.6,0-13.2-6.6-13.2-13.2l0,0c0-6.6,6.6-13.2,13.2-13.2h469.6
   c6.6,0,13.2,6.6,13.2,13.2l0,0C717.8,549.4,711.2,556,704.6,556z"/>
      </svg>
    </div>
  );
}
export default HammerNailImage;
