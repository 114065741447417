import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storeActions from '../actions/StoresActions';
import * as signUpActions from '../actions/SignupActions';
import { pageViewGA } from '../services/GoogleAnalytics';
import {Redirect} from 'react-router-dom';
import HeadPage from '../components/HeadPage';
import ChooseCustomerType from '../components/SignUp/ChooseCustomerType';
import ChooseITMStore from '../components/SignUp/ChooseITMStore';
import ClosestITMStore from '../components/SignUp/ClosestITMStore';
import CompleteSignUp from '../components/SignUp/CompleteSignUp';
import StoresSearch from '../components/SignUp/StoresSearch';
import SignOut from '../components/Login/SignOut';
import SignedOut from '../components/Login/SignedOut';
import StickyNavigation from '../components/StickyNavigation';

class SignUpContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { pageType, actions } = this.props;

    actions.loadStores();

    switch (pageType) {
      case 'Choose ITM Store':
        pageViewGA('Choose ITM Store');
        break;
      case 'Closest ITM Store':
        pageViewGA('Closest ITM Store');
        break;
      case 'Search Stores':
        pageViewGA('Search Stores');
        break;
      default:
        break;
    }
  }

  renderStoreSearch() {
    return (
      <>
        <HeadPage
          pageTitle="Our stores"
          titleBgColor="white"
          pageDescription="Find your nearest ITM from our locations nationwide."
        />
        <StoresSearch />
      </>
    );
  }

  renderClosestITMStore() {
    const { actions, stores, signUp } = this.props;

    var url = new URL(window.location.href);
    var id = Number(url.searchParams.get('id'));

    const store = stores.find((item) => item?.NodeID === id);
    const name = store && store.Name ? store.Name : '';
    const physicalAddress =
      store && store.PhysicalAddress ? store.PhysicalAddress : '';
    const postalAddress =
      store && store.PostalAddress ? store.PostalAddress : '';
    const openingHours = store && store.OpeningHours ? store.OpeningHours : '';
    const phoneNumber = store && store.PhoneNumber ? store.PhoneNumber : '';
    const storeImg = store && store.Image ? store.Image : '';
    const storeId = store && store.NodeID ? Number(store.NodeID) : 0;
    const description = `Based on the location service, we detected ${name} is your closest store, can you please confirm below.`;

    const handleStoreSelect = (storeId) => {
      actions.userUpdateSignupProfile({storeId: Number(storeId)});
      actions.userSignupCreate();
    };

    if (signUp.stage === 'complete') {
      return <Redirect to='/'/>;
    }

    return (
      <>
        <HeadPage
          pageTitle="Your Closest ITM Store"
          titleBgColor="white"
          pageDescription={description}
        />
        <ClosestITMStore
          storeName={name}
          storePhysicalAddress={physicalAddress}
          storePostalAddress={postalAddress}
          storeOpeningHours={openingHours}
          storePhoneNumber={phoneNumber}
          storeImg={storeImg != null && storeImg}
          storeID={storeId}
          handleStoreSelect={handleStoreSelect}
        />
      </>
    );
  }

  renderChooseITMStore() {
    const { actions } = this.props;

    const handleLocationService = (latitude, longitude) => {
      actions.loadStoresByLocation(latitude, longitude, 'store detail');
    };
    return (
      <>
        <HeadPage
          pageTitle="Choose your <br/> local itm store"
          titleBgColor="white"
          pageDescription="To help us find ITM stores in your local area, enable location services on your device."
        />
        <ChooseITMStore handleLocationService={handleLocationService} />
      </>
    );
  }

  renderChooseCustomerType() {
      return (
        <>
          <HeadPage
            pageTitle="What type of <br/> customer are you?"
            titleBgColor="white"
            pageDescription="We'll tailor news and other content that's most relevant for you."
          />
          <ChooseCustomerType />
        </>
      );
  }

  renderLogout() {
    return (
      <>
        <HeadPage
          pageTitle="Sign out"
          titleBgColor="white"
          pageDescription="Are you sure you wish to sign-out?"
        />
        <SignOut />
        <StickyNavigation />
      </>
    );
  }

  renderLoggedOut() {
    return (
      <>
        <HeadPage
          pageTitle="Session ended"
          titleBgColor="white"
          pageDescription="Your session has ended. You may sign in again or close the application."
        />
        <SignedOut />
      </>
    );
  }

  renderCompleteSignup() {
    return <CompleteSignUp />;
  }

  render() {
    const { pageType } = this.props;
    return (
      <>

        {pageType === 'Customer Type' && this.renderChooseCustomerType()}

        {pageType === 'Logout' && this.renderLogout()}

        {pageType === 'Logged Out' && this.renderLoggedOut()}

        {pageType === 'Complete Signup' && this.renderCompleteSignup()}

        {pageType === 'Choose ITM Store' && this.renderChooseITMStore()}

        {pageType === 'Closest ITM Store' && this.renderClosestITMStore()}

        {pageType === 'Search Stores' && this.renderStoreSearch()}
      </>
    );
  }
}

SignUpContainer.propTypes = {
  pageType: PropTypes.string,
  signUp: PropTypes.object,
  user: PropTypes.object,
  storesByLocation: PropTypes.array,
  stores: PropTypes.array,
  actions: PropTypes.object,
};

SignUpContainer.defaultProps = {
  pageType: null,
  storesByLocation: [],
  stores: [],
  signUp: {},
  user: {},
};

const mapStateToProps = (state) => {
  const {
    stores: { storesByLocation, stores },
    signUp,
    auth: { user },
  } = state;
  return {
    storesByLocation,
    stores,
    signUp,
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...storeActions, ...signUpActions}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
