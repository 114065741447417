import React from 'react';
import PropTypes from 'prop-types';

const LbpActivityLogCard = (props) => {
    const {title, description, date, hours} = props;
    return (
        <div className="activity-log-card">
            <div>
                <h4 className="activity-log-card__title">{title}</h4>
                <p className="activity-log-card__description small-text two-line-clamp">{description}</p>
            </div>
            { date && <div className="activity-log-card__score">
                <span className="activity-log-card__score__date">{date}</span>
                { hours &&
                <>
                    <span className="activity-log-card__score__number">{hours}</span>
                    <span className="activity-log-card__score__points">HOURS</span>
                </>   
                 }
            </div> } 
        </div>
    );
};

LbpActivityLogCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  hours: PropTypes.number,
  handleClick: PropTypes.func,
};

LbpActivityLogCard.defaultProps = {
  title: '',
  description: '',
  date: null,
  hours: 0,
  handleClick: () => {},
};


export default LbpActivityLogCard;