import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

const CardSlider = (props) => {
  const { children } = props;
  const sliderLenth = children.length;
  // Set initial slider index and change slider index as slides change
  const [slideIndex, setSlideIndex] = useState(1);
  // Used to navigate slider forward and backward
  const sliderRef = useRef();

  const HandleArrowNextClick = () => {
    sliderRef.current.slickNext();
  };

  const HandleArrowPrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const customSliderControls = (dots) => {
    const currentSliderIndex = ('0' + slideIndex).slice(-2);
    const totalSlides = ('0' + sliderLenth).slice(-2);

    return (
    <div className="card-slider__controls">
        <div className="card-slider__controls--next" onClick={HandleArrowPrevClick} >
          <i className="fas fa-arrow-left"></i>{currentSliderIndex}
        </div>
        <ul className={`_${sliderLenth}`}>{dots}</ul>
        <div className="card-slider__controls--prev" onClick={HandleArrowNextClick} >
        {totalSlides} <i className="fas fa-arrow-right"></i>
        </div>
    </div>
    );
  };
  
  let settings = {
    dots: true,
    arrows: false,
    variableWidth: true,
    centerMode: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: dots => customSliderControls(dots),
    beforeChange: (current, next) => setSlideIndex(next + 1)
  };

  return (
    <Slider className="card-slider" ref={sliderRef} {...settings}>
      {children}
    </Slider>
  );
}; 

CardSlider.defaultProps = {
  children: []
};

CardSlider.propTypes = {
  children: PropTypes.array
};

export default CardSlider; 