import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../util/dates';
import { Link } from 'react-router-dom';

const ListingCard = (props) => {
  const { variant,
    publishedDate,
    heading,
    image,
    linkUrl
  } = props;

  return (
    <Link to={linkUrl} className="transform-none" >
      <div className={`listing-card listing-card--${variant}`}>
        <div className="listing-card__wrapper">
          <div className="listing-card__img -bg-image" style={{ backgroundImage: `url(${image})` }} ></div>
          <div className="listing-card__content z-index-one">
            {variant === 'news' && (
              <div className="listing-card__date">
                <p className="small-text">{formatDate(publishedDate)}</p>
              </div>)}
            <div className="listing-card__heading">
              <h4>{heading}</h4>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

ListingCard.defaultProps = {
  variant: null,
  publishedDate: null,
  heading: null,
  image: null,
  linkUrl: null
};

ListingCard.propTypes = {
  variant: PropTypes.string,
  publishedDate: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.string,
  linkUrl: PropTypes.string
};



export default ListingCard;
