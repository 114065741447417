import React from 'react';
import PropTypes from 'prop-types';
import HammerNailImage from '../svg/hammerNailImage';
import DocumentLibraryFeature from './DocumentLibraryFeature';

const DocumentSearchResults = ({ search, fetchingSearchResult }) => {
  const categoryItems = search.DocumentLibraryCategories || [];
  const supplierItems = search.DocumentLibraries || [];
  const documentItems = search.DocumentLibraryMedias || [];
  const found =
    categoryItems.length > 0 ||
    supplierItems.length > 0 ||
    documentItems.length > 0;

  return (
    <div className="documents-library-feature--search">
      {fetchingSearchResult && <HammerNailImage />}
      {!found && !fetchingSearchResult && (
        <div className="documents-library-feature--search__no-result">
          <p>
            <i className="fas fa-exclamation"></i>
            Oops, nothing matches that search term, please try your search again
          </p>
        </div>
      )}
      {found && (
        <>
          {categoryItems.length > 0 && (
            <DocumentLibraryFeature
              title="Categories"
              type="Categories"
              cards={categoryItems}
              maxLength={5}
            />
          )}
          {supplierItems.length > 0 && (
            <DocumentLibraryFeature
              title="Suppliers"
              type="Media"
              cards={supplierItems}
              maxLength={5}
            />
          )}
          {documentItems.length > 0 && (
            <DocumentLibraryFeature
              title="Document"
              type="Document"
              stack={true}
              cards={documentItems}
              maxLength={5}
            />
          )}
        </>
      )}
    </div>
  );
};

DocumentSearchResults.defaultProps = {
  search: {},
  fetchingSearchResult: false,
};
DocumentSearchResults.propTypes = {
  search: PropTypes.object,
  fetchingSearchResult: PropTypes.bool,
};

export default DocumentSearchResults;
