import * as types from './ActionTypes';

export const userLoggedIn = (account) => {
    return {
        type: types.AUTH_USER_LOGGED_IN,
        account
    };
};

export const userLogout = () => {
    return {
        type: types.AUTH_USER_LOGOUT
    };
};

