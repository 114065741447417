import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadFavouritesAndRecentlyViewed,
  postDocumentsLibraryFavourites,
} from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibraryCard from '../../components/DocumentsLibrary/DocumentLibraryCard';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const DocumentRecentlyViewed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFavouritesAndRecentlyViewed());
    pageViewGA('Document Recently Viewed');
  }, [dispatch]);

  const { mediaRecentlyViewed, mediaFavourites } = useSelector(
    (state) => state.documents,
  );

  const handleSelectFavourite = (id) => {
    dispatch(postDocumentsLibraryFavourites(id));
  };

  const cards = mediaRecentlyViewed.map((card) => {
    const isFavourite =
      mediaFavourites &&
      mediaFavourites.findIndex((item) => item.NodeID === card.NodeID) >= 0;

    const props = {
      title: card.Title,
      id: card.NodeID,
      isFullWidth: true,
      linkUrl: card.Link,
      iconType: card.Type,
      isFavourite,
      handleSelectFavourite,
    };

    return <DocumentLibraryCard key={card.NodeID} {...props} />;
  });

  return (
    <ActionPageLayout
      pageTitle="Recently Viewed"
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <div className="cards-list cards-list--stack">{cards}</div>
    </ActionPageLayout>
  );
};

DocumentRecentlyViewed.propTypes = {};
DocumentRecentlyViewed.defaultProps = {};
