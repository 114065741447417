import React from 'react';
import CtaButton from '../Button/CtaButton';
import PropTypes from 'prop-types';

const PriorityCard = (props) => {
  const { headingMain, headingSub, description, handleCloseClick } = props;
  return (
    <div className="priority-card">
      <div className="priority-card__wrapper">
        <div className="priority-card__wrapper__close">
          <button className="priority-card__wrapper__close-btn" onClick={handleCloseClick}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="priority-card__heading">
          <h2 className="priority-card__heading__text">{headingMain}</h2>
          <h2 className="priority-card__heading__text">{headingSub}</h2>
        </div>
        <div className="priority-card__description">
          <p className="priority-card__description__text">{description}</p>
        </div>
        <div className="priority-card__buttons">
          <div className="priority-card__buttons__cta-btn">
            <CtaButton
              type="primary"
              text={'Link a priority card'}
              href={'/priority-card/link-card'}
              arrowIcon={false}
            />
          </div>
          <div className="priority-card__buttons__link">
            {
              <a href="https://www.itm.tx.co.nz/store" target='_blank' className="text-link" rel="noreferrer">
                Sign up
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

PriorityCard.defaultProps = {
  headingMain: null,
  headingSub: null,
  description: null,
};

PriorityCard.propTypes = {
  headingMain: PropTypes.string,
  handleCloseClick: PropTypes.func,
  headingSub: PropTypes.string,
  description: PropTypes.string,
};
export default PriorityCard;
