import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetNewsPosts = async (categoriesId, storeId) => {
  const {data} = await Axios.get(buildRequestUrl('/api/content/news/get'), {
    params: { topn: 10, categories: categoriesId, stores: storeId },
  });

  return data;
};

export const GetNewsInterests = async () => {
  const {data} = await Axios.get(buildRequestUrl('/api/content/news/categories/get'));

  return data;
};
