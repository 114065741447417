import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadNewsInterestsByUser,
  postNewsInterestsByUser,
} from '../../actions/NewsActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import NewsInterest from '../../components/News/NewsInterests';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const NewsInterests = () => {
  const dispatch = useDispatch();
  const { selectedNewsInterests, loading, newsPage } = useSelector(
    (state) => state.news,
  );

  useEffect(() => {
    dispatch(loadNewsInterestsByUser());
    pageViewGA('News interests');
  }, [dispatch]);

  const title = selectedNewsInterests?.PageInfo?.Title || 'My News Interests';
  const subtitle =
    selectedNewsInterests?.PageInfo?.Subtitle ||
    'Select topics that interest you';

  const handleUserSelectedInterests = (selectedInterests) => {
    dispatch(postNewsInterestsByUser(selectedInterests));
  };

  return (
    <ActionPageLayout
      pageTitle={title}
      pageDescription={subtitle}
      loading={loading}
    >
      <NewsInterest
        userSelectedNewsInterests={selectedNewsInterests?.Items}
        handleUserSelectedInterests={handleUserSelectedInterests}
        interetsSaveBtn={newsPage.interestsSaveBtnDisable}
      />
    </ActionPageLayout>
  );
};

NewsInterests.propTypes = {};
NewsInterests.defaultProps = {};
