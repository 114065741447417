import React from 'react';
import HeadPage from '../HeadPage';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PriorityCardSettings = (props) => {
  const { title, description, PriorityCardNumber } = props;
  return (
    <div className="PriorityCardSettings">
      <div className="PriorityCardSettings__wrapper-main">
        <HeadPage
          pageTitle={title}
          hasBackButton={true}
          pageDescription={description}
          titleBgColor={'white'}
        />
        <div className="PriorityCardSettings__description-main">
          <p>Edit your Priority Card details below:</p>
        </div>
        <div className="PriorityCardSettings__description-sub">
          <p>Currently linked to card number </p>
          <p className="card-number">{PriorityCardNumber}</p>
          <p>
            If that’s not you. Try link to{' '}
            <Link to="/account/priority-card-link">new card</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

PriorityCardSettings.defaultProps = {
  title: null,
  description: null,
  PriorityCardNumber: null,
};

PriorityCardSettings.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  PriorityCardNumber: propTypes.number,
};

export default PriorityCardSettings;
