import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ToggleButton = (props) => {
  const { id, text, IsChecked } = props;

  const [inputState, setInputState] = useState({
    [text]: IsChecked
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setInputState({
      [name]: value
    });
  };

  return (
    <div className="btn--toggle">
      <input type="checkbox" id={id} className="sr-only" name={text} checked={inputState[text]} onChange={handleInputChange}/>
      <label className="btn btn--toggle-handler" htmlFor={id} >
        <span>{text}</span>
      </label>
    </div>
  );
};

ToggleButton.defaultProps = {
  id: null,
  text: null,
  IsChecked: false
};

ToggleButton.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  IsChecked: PropTypes.bool
};

export default ToggleButton;