import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewsPostsByUser } from '../../actions/NewsActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import NewsPage from '../../components/News/NewsPage';
import HeadPage from '../../components/HeadPage';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

export const NewsPosts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNewsPostsByUser());
    pageViewGA('News');
    window.scrollTo({top: 0, left: 0});
  }, [dispatch]);

  const { newsPostsByUser, loading } = useSelector((state) => state.news);
  const title = newsPostsByUser?.PageInfo?.Title;
  const items = newsPostsByUser?.Items || [];

  if (loading) {
    return <Loader/>;
  }

  return (
    <>
      <div className="head-page__nav">
        <HeadPage pageTitle={title} hasBackButton={true} />
        <Link className="head-page__nav__right-btn" to="/news/categories">
          {' '}
          <i className="fas fa-sliders-v-square"></i>{' '}
        </Link>
      </div>
      <NewsPage cards={items} />
    </>
  );
};

NewsPosts.propTypes = {};
NewsPosts.defaultProps = {};
