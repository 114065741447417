import { getAuthenticatedAxios, withErrorHandler } from './AuthenticatedAxiosProvider';
import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetActivities = async () => {
  const url = buildRequestUrl('/api/users/lbp/activities/get');
  const {data} = await withErrorHandler(() => getAuthenticatedAxios().get(url));

  return data;
};

export const GetBasics = async () => {
  const url = buildRequestUrl('/api/content/lbp/basics/get');
  const {data} = await Axios.get(url);

  return data;
};

export const GetCodewords = async () => {
  const url = buildRequestUrl('/api/content/lbp/codewords/get');
  const {data} = await Axios.get(url);

  return data;
};

export const GetCodewordsByUser = async () => {
  const url = buildRequestUrl('/api/users/lbp/codewords/get');
  const {data} = await withErrorHandler(() => getAuthenticatedAxios().get(url));

  return data;
};

export const GetLbpLicenceClasses = async () => {
  const url = buildRequestUrl('/api/content/lbp/licenceclasses/get');
  const {data} = await Axios.get(url);

  return data;
};
