import React from 'react';
import Loader from '../components/Loader';
import HeadPage from '../components/HeadPage';
import StickyNavigation from '../components/StickyNavigation';
import PropTypes from 'prop-types';

const ActionPageLayout = (props) => {
  const {
    pageTitle,
    pageDescription,
    hasBackButton,
    titleBgColor,
    loading,
    activeNavigationTab,
    children,
  } = props;

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HeadPage
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        hasBackButton={hasBackButton}
        titleBgColor={titleBgColor}
      />
      {children}
      <StickyNavigation activeTabName={activeNavigationTab} />
    </>
  );
};

ActionPageLayout.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  hasBackButton: PropTypes.bool,
  titleBgColor: PropTypes.string,
  activeNavigationTab: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.element
};

ActionPageLayout.defaultProps = {
  hasBackButton: true,
  loading: false,
  titleBgColor: 'white',
  activeNavigationTab: 'home',
};


export default ActionPageLayout;