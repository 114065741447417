import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDocumentsLibraryCategories } from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibraryCard from '../../components/DocumentsLibrary/DocumentLibraryCard';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const DocumentCategories = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDocumentsLibraryCategories());
    pageViewGA('Document Categories');
  }, [dispatch]);

  const { categories } = useSelector((state) => state.documents);
  const title = categories?.PageInfo?.Title;
  const subtitle = categories?.PageInfo?.Subtitle;
  const items = categories.Items || [];

  const cards = items.map((card, index) => (
    <DocumentLibraryCard
    key={index}
    title={card.Title}
    id={card.NodeID}
    linkUrl={`/document-library/categories/category?id=${card.NodeID}`}
    figurePath={card.Image}
  />    
));

  return (
    <ActionPageLayout
      pageTitle={title}
      pageDescription={subtitle}
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <div className="cards-list">{cards}</div>
    </ActionPageLayout>
  );
};

DocumentCategories.propTypes = {};
DocumentCategories.defaultProps = {};
