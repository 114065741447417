import React from 'react';
import PropTypes from 'prop-types';

const CodewordsIssue = (props) => {
  const {
    issueData: { Title, Categories },
    handleCodewordsClick,
  } = props;

  const handleClick = (codeword) => {
    const {NodeID, Title, Date, Question, Answer} = codeword;
    handleCodewordsClick(NodeID, Title, Date, Question, Answer, false);
  };

  const renderCodeword = (codewords) => {
    const items = codewords.map((codeword,i) => {
      const icons = codeword.Icon.split('|');

      const iconImages = icons.map((icon, j) => (
        <img
          key={j}
          src={`/assets/img/${icon}.png`}
          alt={icon}
        />
      ));

      return (
        <li key={i}>
          { iconImages }
          <button onClick={() => handleClick(codeword)} className="codewords-issue__link">
            {codeword.Title}
          </button>
        </li>
      );
    });
    return (
      <ul className="codewords-issue__list">
        {items}
      </ul>
    );
  };

  return (
    <div className="codewords-issue">
      <div className="codewords-issue__wrapper">
        <div className="codewords-issue__title">
          <h2>{Title}</h2>
        </div>
        <div className="codewords-issue__content">
          {Categories.map((category, i) => {
            return( 
              <div className="codewords-issue__group" key={i}>
                <h3 key={i} className="codewords-issue__subtitle">{category.Title}</h3>
                { renderCodeword(category.Codewords)}
              </div> 
            );
          })}
        </div>
      </div>
    </div>
  );
};

CodewordsIssue.propTypes = {
  issueData: PropTypes.object,
  handleCodewordsClick: PropTypes.func,
};

CodewordsIssue.defaultProps = {
  handleCodewordsClick: (()=>{})
};

export default CodewordsIssue;
