import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CarouselCard = (props) => {
  const { heading, image, publishedDate, subheading, linkUrl } = props;

  return (
    <Link to={linkUrl} className="transform-none" >
      <div className="carousel-card__wrapper">
        <div className="carousel-card__heading">
          <p className="tag text--uppercase">{heading}</p>
        </div>
        <div className="carousel-card__img -bg-image"
          style={{ backgroundImage: `url(${image})` }}
        >
        </div>
        <div className="carousel-card__date">
          <p className="small-text">{publishedDate}</p>
        </div>
        <div className="carousel-card__subheading">
          <h4>{subheading}</h4>
        </div>
      </div>
    </Link>
  );
};

CarouselCard.defaultProps = {
  heading: null,
  image: null,
  publishedDate: null,
  subheading: null,
  linkUrl: '/'
};

CarouselCard.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.string,
  publishedDate: PropTypes.string,
  subheading: PropTypes.string,
  linkUrl: PropTypes.string
};

export default CarouselCard;
