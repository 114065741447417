import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  dealsPage: {
    updatePosts: true
  },
};

export default function DealsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_DEALS_POSTS_BY_USERS_START:
      return {
        ...state,
        loading: action.loading,
      };
    case types.LOAD_DEALS_POSTS_BY_USERS_COMPLETE:
      return {
        ...state,
        dealsPostsByUser: action.dealsPostsByUser,
        loading: false,
        dealsPage: { ...state.dealsPage, updatePosts: false },
      };

    default:
      return state;
  }
}
