import React from 'react';
import PropTypes from 'prop-types';

const Modal = (props) => {
  const { handleClose, handleModalVisibility } = props;

  const modifier = handleModalVisibility ? 'show' : 'hide';

  return (
    <div className={`modal ${modifier}`}>
      <div className="modal__container">
        <button type="button" className="modal__close-btn" onClick={handleClose}>
          Close <i className="fas fa-times"></i>
        </button>

        {props.children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  handleClose: PropTypes.func,
  handleModalVisibility: PropTypes.bool,
  children: PropTypes.array
};

Modal.defaultProps = {
  handleClose: null,
  handleModalVisibility: false
};

export default Modal;
