import {userSignupStageUpdate} from './SignupActions';
import { GetUserDetails } from '../api/GetUserDetails';
import {loadUserDetailsSuccess} from './AccountsActions';
import { userLoggedIn } from './AuthActions';

export const userPostAuthentication = (account) => async (dispatch, getState) => {
    try {
        dispatch(userLoggedIn(account));

        const {signUp} = getState();
        if (signUp?.stage === 'unknown') {
            const profile = await GetUserDetails();
            dispatch(loadUserDetailsSuccess({profile}));
            dispatch(userSignupStageUpdate('complete'));
        }
    } catch (ex) {
        if (ex.response && ex.response.status === 404) {
            dispatch(userSignupStageUpdate('start'));
        } else {
            // Let someone else handle the problem
            throw ex;
        }
        
    }
};