import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

const PromptCard = (props) => {
  const { heading, description, handleNextClick, handleCloseClick, link } = props;
  const history = useHistory();


  const handleClickAction = () => {
    if (link) {
      history.push(link);
    } else if (handleNextClick) {
      handleNextClick();
    }
  };

  return (
    <div className="prompt-card">
      <div className="prompt-card__wrapper">
        <h3 className="black-highlight" dangerouslySetInnerHTML={{ __html: heading }}></h3>
        <p className="small-text">{description}</p>
        {handleCloseClick && <button className="prompt-card__close-btn" onClick={handleCloseClick}>
          <i className="far fa-times"></i>
        </button>}
        <div>
          <button onClick={handleClickAction} className="prompt-card__next-btn">
            <div className="prompt-card__next-btn__backdrop"></div>
            <i className="far fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

PromptCard.defaultProps = {
  heading: null,
  link: null,
  description: null,
  handleNextClick: null,
  handleCloseClick: null,
};

PromptCard.propTypes = {
  heading: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  handleNextClick: PropTypes.func,
  handleCloseClick: PropTypes.func,
};

export default PromptCard;
