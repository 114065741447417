import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetCustomerType = async () => {
  const { data } = await Axios.get(
    buildRequestUrl('/api/users/customertypes/get'),
  );

  return data;
};
