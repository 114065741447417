import React, { useEffect } from 'react';
import Button from '../Form/Button';
import HeadPage from '../HeadPage';
import propTypes from 'prop-types';
import TextBox from '../Form/TextBox';
import { inputChangeHideKeyboardOverlay } from '../../util/mobile';

const PriorityCardLink = (props) => {
  const { title, handlePriorityCardLink } = props;

  useEffect(() => {
    // Only for Android
    inputChangeHideKeyboardOverlay();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = e.target.querySelector('input').value;

    if (handlePriorityCardLink) {
      handlePriorityCardLink(data);
    }
  };

  return (
    <div className="PriorityCardLink">
      <div className="PriorityCardLink__wrapper-main">
        <HeadPage
          pageTitle={title}
          hasBackButton={true}
          titleBgColor={'white'}
        />
        <div className="PriorityCardLink__description">
          <p>Please enter your priority card number. This number starts with 9 and is followed by seven digits.</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextBox label="Card Number" value="" />
          <div className="PriorityCardLink__wrapper-sub">
            <Button text="Link Card" />
            <div className="PriorityCardLink__hr"></div>
            <div className="PriorityCardLink__bottom-text">
              <p>Don’t have an account?</p>
              <a href="https://www.itm.tx.co.nz/store" target="_blank" rel="noreferrer">
                Sign up
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
PriorityCardLink.defaultProps = {
  title: null,
  handlePriorityCardLink: () => {},
};

PriorityCardLink.propTypes = {
  title: propTypes.string,
  handlePriorityCardLink: propTypes.func,
};

export default PriorityCardLink;
