import React from 'react';
import PropTypes from 'prop-types';
import StickyButton from '../Form/StickyButton';
import ToggleButton from '../Form/ToggleButton';

const NewsInterest = (props) => {
  const {
    userSelectedNewsInterests,
    handleUserSelectedInterests,
    interetsSaveBtn,
  } = props;

  const handleSubmit = (event) => {
    event.preventDefault();

    const formInputElements = Array.from(
      event.target.querySelectorAll('input[type=\'checkbox\']'),
    );

    const selectedInterests = formInputElements
      .filter((elem) => elem.checked)
      .map((elem) => Number(elem.id));

    handleUserSelectedInterests(selectedInterests);
  };

  if (userSelectedNewsInterests) {
    return (
      <form className="news-interests" onSubmit={handleSubmit}>
        {userSelectedNewsInterests.map((news) => (
          <ToggleButton
            key={news.NodeID}
            id={news.NodeID}
            text={news.Title}
            IsChecked={news?.IsChecked}
          />
        ))}
        <StickyButton text="Save" btnDisabled={interetsSaveBtn} />
      </form>
    );
  }
};

NewsInterest.propTypes = {
  userSelectedNewsInterests: PropTypes.array.isRequired,
  handleUserSelectedInterests: PropTypes.func,
  interetsSaveBtn: PropTypes.bool
};

NewsInterest.defaultProps = {
  userSelectedNewsInterests: [],
  interetsSaveBtn: false
};

export default NewsInterest;
