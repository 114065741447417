import { getAuthenticatedAxios, withErrorHandler } from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const PostNewsInterests = async (selectedInterests) => {
  const payload = { News: { NewsCategories: selectedInterests.join(',') } };
  
  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      payload,
    ),
  );

  return data;
};
