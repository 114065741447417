import React from 'react';
import SimplePageLayout from '../../layouts/SimplePageLayout';
import Button from '../../components/Form/Button';
import PropTypes from 'prop-types';

export const ApplicationError = () => {

  const onButtonClick = () => {
    // Invoke a full page refresh
    location.href = '/';
  };

  return (
    <SimplePageLayout
      pageTitle="Technical Error"
      pageDescription="Something went wrong..."
      hasBackButton={false}
    >
      <div className="SystemError__description">
        <p>Thats a bit embarrasing, but something went wrong with our application.</p>
      </div>
      
      <div>
        <Button
          text="Try again"
          type="primary"
          handleSubmit={onButtonClick}
        />
      </div>
    </SimplePageLayout>
  );
};

ApplicationError.propTypes = {
  error: PropTypes.object,
};

ApplicationError.defaultProps = {};
