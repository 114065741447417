import React from 'react';
import PropTypes from 'prop-types';
import DocumentLibraryCard from './DocumentLibraryCard';
import { Link } from 'react-router-dom';

const DocumentLibraryFeature = (props) => {
  const {
    title,
    type,
    cards,
    stack,
    seeAllUrl,
    maxLength,
    handleSelectFavourite,
    handleRecentlyViewed,
    favourites,
  } = props;

  const showTiles = cards.length > 0;
  let infoMessage =
    'Start adding documents to your favourites by tapping the star icon in the lower right corner when you find something useful!';
  if (type === 'Recently Viewed') {
    infoMessage =
      'Your recently viewed documents will start to appear here soon.';
  }

  // if it is stack, the cards should also be full width
  const fullWidthCard = stack;

  const displayCards = cards.map((card, index) => {
    if (maxLength !== null && index > maxLength - 1) {
      return;
    }

    const isFavourite =
      favourites &&
      favourites.findIndex((item) => item.NodeID === card.NodeID) >= 0;

    let linkUrl = `/document-library/media?id=${card.NodeID}`;
    if (type === 'Categories') {
      linkUrl = `/document-library/categories/category?id=${card.NodeID}`;
    } else if (
      type === 'Favourites' ||
      type === 'Recently Viewed' ||
      type === 'Document'
    ) {
      linkUrl = card.Link;
    }

    const props = {
      isFullWidth: fullWidthCard,
      title: card.Title,
      linkUrl,
      figurePath: card.Image,
      iconType: card.Type,
      id: card.NodeID,
      handleSelectFavourite,
      handleRecentlyViewed,
      isFavourite,
    };

    return <DocumentLibraryCard key={index} {...props} />;
  });

  return (
    <section
      className={`documents-library-feature ${
        stack ? 'documents-library-feature--stack' : ''
      }`}
    >
      <div className="documents-library-feature__header">
        <h3 className="documents-library-feature__header__title">{title}</h3>
        {showTiles && seeAllUrl && (
          <Link to={seeAllUrl} className="text-link text-link--uppercase">
            SEE ALL
          </Link>
        )}
      </div>
      {showTiles ? (
        <div className="documents-library-feature__tiles">
          {!stack ? (
            <div className="documents-library-feature__tiles documents-library-feature__tiles--horizontal">
              {displayCards}
            </div>
          ) : (
            displayCards
          )}
        </div>
      ) : (
        <p className="documents-library-feature__info-message small-text">
          {infoMessage}
        </p>
      )}
    </section>
  );
};

DocumentLibraryFeature.defaultProps = {
  title: 'Title not defined',
  type: '',
  cards: [],
  stack: false,
  seeAllUrl: null,
  maxLength: null,
  handleSelectFavourite: null,
  favourites: null,
};

DocumentLibraryFeature.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  cards: PropTypes.array,
  stack: PropTypes.bool,
  seeAllUrl: PropTypes.string,
  maxLength: PropTypes.number,
  handleSelectFavourite: PropTypes.func,
  handleRecentlyViewed: PropTypes.func,
  favourites: PropTypes.array,
};

export default DocumentLibraryFeature;
