import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  priorityCardDetails : {}
};

export default function PriorityCardReducer(state = initialState, action) {

  switch (action.type) {
    case types.LOAD_PRIORITY_CARD_DETAILS_START:
      return {
        ...state,
        loading: true
      };
    case types.LOAD_PRIORITY_CARD_DETAILS_COMPLETE:
      return {
        ...state,
        loading: false,
        priorityCardDetails: action.userDetails,
      };
    case types.POST_PRIORITY_CARD_DETAILS_START:
      return {
        ...state,
        loading :action.loading
      };
    case types.POST_PRIORITY_CARD_DETAILS_COMPLETE:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
}
