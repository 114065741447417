import * as types from '../actions/ActionTypes';

const initialState = {
  stores: [],
  storesByLocation: [],
  getLocation: {}
};

export default function StoresReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_STORE_DETAILS_BY_LOCATION:
      return {
        ...state,
        storesByLocation: action.storeDetailsByLocation
      };
    case types.LOAD_STORE_DETAILS:
      return {
        ...state,
        stores: action.storeDetails
      };
    default:
      return state;
  }
}
