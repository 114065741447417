import React from 'react';
import CtaButton from '../Button/CtaButton';
import PropTypes from 'prop-types';

const TradeClubCard = (props) => {
  const { headingMain, headingSub, description, handleButtonClose } = props;
  return (
    <div className="TradeClubCard">
      <div className="TradeClubCard__wrapper">
        <div className="TradeClubCard__wrapper__close">
          <button className="TradeClubCard__wrapper__close-btn" onClick={handleButtonClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="TradeClubCard__heading">
          <h2 className="TradeClubCard__heading__text">{headingMain}</h2>
          <h2 className="TradeClubCard__heading__text">{headingSub}</h2>
        </div>
        <div className="TradeClubCard__description">
          <p className="TradeClubCard__description__text">{description}</p>
        </div>
        <div className="TradeClubCard__buttons">
          <div className="TradeClubCard__buttons__cta-btn">
            <CtaButton
              type="primary"
              text={'Link Trade Club Number'}
              href={'/account/trade-club-link'}
              arrowIcon={false}
            />
          </div>
          
        </div>
      </div>
    </div>
  );
};
TradeClubCard.defaultProps = {
  headingMain: null,
  headingSub: null,
  description: null,
  handleButtonClose: () => {},
};

TradeClubCard.propTypes = {
  headingMain: PropTypes.string,
  headingSub: PropTypes.string,
  description: PropTypes.string,
  handleButtonClose: PropTypes.func,
};
export default TradeClubCard;
