import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const DealsArticle = (props) => {
  const {
    articleName,
    articleImage,
    articleTopContent,
    articleMiddleContent,
    articleBottomContent,
    articleQuote,
  } = props;

  useEffect( () => {
    window.scrollTo({top: 0, left: 0});
  }, []);  

  return (
    <>
      {articleTopContent && (
        <div dangerouslySetInnerHTML={{ __html: articleTopContent }} />
      )}
      {articleImage && (
        <div className="article-page__img">
          <img src={articleImage} alt={articleName + ' Image'} />
        </div>
      )}
      {articleQuote && (
        <div className="article-page__quote">
          <blockquote> {articleQuote}</blockquote>
        </div>
      )}
      {articleMiddleContent && (
        <div dangerouslySetInnerHTML={{ __html: articleMiddleContent }} />
      )}
      {articleBottomContent && (
        <div dangerouslySetInnerHTML={{ __html: articleBottomContent }} />
      )}
    </>
  );
};

DealsArticle.propTypes = {
  articleName: PropTypes.string,
  articleImage: PropTypes.string,
  articleTopContent: PropTypes.string,
  articleMiddleContent: PropTypes.string,
  articleBottomContent: PropTypes.string,
  articleQuote: PropTypes.string,
};

DealsArticle.defaultProps = {
  articleName: null,
  articleImage: null,
  articleTopContent: null,
  articleMiddleContent: null,
  articleBottomContent: null,
  articleQuote: null,
};

export default DealsArticle;
