import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDocumentsLibrary } from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibraryCard from '../../components/DocumentsLibrary/DocumentLibraryCard';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const DocumentCategory = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    dispatch(loadDocumentsLibrary(id));
    pageViewGA('Document Category');
  }, [dispatch]);

  const { documentsByCategory } = useSelector((state) => state.documents);
  const title = documentsByCategory?.PageInfo?.Title;
  const subtitle = documentsByCategory?.PageInfo?.Subtitle;
  const items = documentsByCategory.Items || [];

  const cards = items.map((card, index) => {
    if (card.Image === '') {
      return (
        <DocumentLibraryCard
          key={index}
          title={card.Title}
          linkUrl={`/document-library/media?id=${card.NodeID}`}
        />
      );
    } else {
      return (
        <DocumentLibraryCard
          key={index}
          linkUrl={`/document-library/media?id=${card.NodeID}`}
          figurePath={card.Image}
        />
      );
    }
  });

  return (
    <ActionPageLayout
      pageTitle={title}
      pageDescription={subtitle}
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <div className="cards-list">{cards}</div>
    </ActionPageLayout>
  );
};

DocumentCategory.propTypes = {};
DocumentCategory.defaultProps = {};
