import React from 'react';
import Button from '../Form/Button';
import {useHistory} from 'react-router-dom';

const SignedOut = () => {
  const history = useHistory();
  
  const onButtonClick = () => {
    /* Navigating to the home page should cause reauthentication */
    history.push('/');
  };

  return (
    <>
      <div>
        <Button text="Sign in" type="primary" handleSubmit={onButtonClick}/>
      </div>
    </>
  );
};

SignedOut.propTypes = {

};

SignedOut.defaultProps = {

};


export default SignedOut;