import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Form/Button';
import { Link } from 'react-router-dom';

const ClosestITMStore = (props) => {
  const { storeName, storePhysicalAddress, storePostalAddress, storeOpeningHours,  storePhoneNumber, storeImg, storeID, handleStoreSelect } = props;

  return (
    <div className="closest-itm-store horizontal-spacing">
      <div className="closest-itm-store__wrapper">
        <div className="closest-itm-store__img">
          <img src={storeImg} alt="Store image" />
        </div>
        <h3 className="closest-itm-store__store-name">{storeName}</h3>
        <div className="closest-itm-store__address">
          <div>
            <h4 className="closest-itm-store__title">Address</h4>
            <p className="no-gutter" dangerouslySetInnerHTML={{ __html: storePhysicalAddress }}></p>
          </div>
          <div>
            <h4 className="closest-itm-store__title">Postal</h4>
            <p className="no-gutter" dangerouslySetInnerHTML={{ __html: storePostalAddress }}></p>
          </div>
        </div>
        <div className="closest-itm-store__phone-no">
          <h4 className="closest-itm-store__title">Phone</h4>
          <a href={`tel:${storePhoneNumber}`} ><p className="no-gutter">{storePhoneNumber}</p></a>
        </div>
        <div className="closest-itm-store__online-hours">
          <h4 className="closest-itm-store__title">Opening hours</h4>
          <div dangerouslySetInnerHTML={{ __html: storeOpeningHours }}></div>
        </div>
        <div className="closest-itm-store__sticky-footer sticky-bottom text--centered">
          <Button type="primary" text="Select this store" handleSubmit={() => handleStoreSelect(storeID)} />
          <Link to="/sign-up/search-stores" className="closest-itm-store__link sticky-bottom__link transform-none">
            <p className="text--bold"><span>or</span>choose a different store</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

ClosestITMStore.propTypes = {
  storeName: PropTypes.string,
  storePhysicalAddress: PropTypes.string,
  storePostalAddress: PropTypes.string,
  storeOpeningHours: PropTypes.string,
  storePhoneNumber: PropTypes.string,
  storeImg: PropTypes.string,
  storeID: PropTypes.number,
  handleStoreSelect: PropTypes.func
};

ClosestITMStore.defaultProps = {
  storeName: null,
  storePhysicalAddress: null,
  storePostalAddress: null,
  storeOpeningHours: null,
  storePhoneNumber: null,
  storeImg: null,
  storeID: 0,
  handleStoreSelect: (() => { })
};

export default ClosestITMStore;