import Axios from 'axios';
import { getBearerToken } from '../services/Msal';
import { store } from '../stores/main';
import { systemError } from '../actions/SystemActions';

export function getAuthenticatedAxios() {
  const instance = Axios.create({
    timeout: 10000,
  });

  instance.interceptors.request.use(
    async (request) => {
      console.debug('Starting http request', { url: request.url });

      try {
        const token = await getBearerToken();
        request.headers.Authorization = `Bearer ${token}`;
      } catch (ex) {
        console.debug(
          `Cancelling request ${request.url} having no bearer token.`,
        );

        return {
          ...request,
          cancelToken: new Axios.CancelToken((cancel) =>
            cancel(`Not authorised for ${request.url}`),
          ),
        };
      }

      return request;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return instance;
}

const AuthorisationError = {
  type: 'Unauthorised',
  message: 'You are unauthorised to view this resource',
  page: '/error/unauthorised',
};

const SystemUnavailableError = {
  type: 'Unavailable',
  message: 'It looks like our system isn\'t available right now.',
  page: '/error/application',
};

/**
 * Handle unauthorised API responses (and others if needed) centrally
 *
 * Right now this is UGLY with a hard redirect to error pages which causes
 * a full page reload.
 *
 * @param {} request
 * @returns
 */
export async function withErrorHandler(request) {
  try {
    return await request();
  } catch (ex) {
    console.debug('withErrorHandler exception', ex);

    if (ex.response) {
      switch (ex.response.status) {
        case 401:
        case 403:
          // Go to unauthorised page
          store.dispatch(systemError(AuthorisationError));
          break;

        case 500:
        case 501:
        case 502:
        case 503:
          store.dispatch(systemError(SystemUnavailableError));
          break;

        default:
          // Caller can handler other status codes
          throw ex;
      }
    }

    /**
     * If the request was canceled then this means there was no authentication context
     */
    if (ex.__CANCEL__) {
      store.dispatch(systemError(AuthorisationError));
    }
  }
}
