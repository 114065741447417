import React, { useState, useEffect } from 'react';
import RadioButton from '../Form/RadioButton';
import Button from '../Form/Button';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  userUpdateSignupProfile,
  userSignupStageUpdate,
} from '../../actions/SignupActions';
import { useHistory } from 'react-router';

const ChooseCustomerType = () => {
  const [submitBtn, setSubmitBtn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(userSignupStageUpdate('customer-type'));
  }, [dispatch]);

  const handleContinueClick = (values) => {
    dispatch(
      userUpdateSignupProfile({
        customerType: values.customerType,
      }),
    );

    history.push('/sign-up/choose-store');
  };

  const initialFormValues = {
    customerType: '',
  };

  // yup validtion schema, to set rules for validation
  const validate = yup.object({
    customerType: yup.string(),
  });

  // By default Formik handles onChange of the Form element, But here we are sending custom because we need to show the button only after the user selects the value.
  const handleCustomerTypeChange = () => {
    setSubmitBtn(true);
  };

  const customerTypeForm = () => {
    return (
      <>
        <Form className="">
          <RadioButton
            id={1}
            text="LBP Builder"
            name="customerType"
            extraClass="btn--toggle--no-padding-top"
            value={4}
            onValueChange={handleCustomerTypeChange}
          />
          <RadioButton
            id={2}
            text="Unlicensed Builder"
            name="customerType"
            value={3}
            onValueChange={handleCustomerTypeChange}
          />
          <RadioButton
            id={3}
            text="Serious DIYer"
            name="customerType"
            value={1}
            onValueChange={handleCustomerTypeChange}
          />
          <RadioButton
            id={4}
            text="Farm / Lifestyle Block Owner"
            name="customerType"
            value={2}
            onValueChange={handleCustomerTypeChange}
          />
          {submitBtn && (
            <div className="sticky-bottom">
              <Button text="Continue" type="primary" />
            </div>
          )}
        </Form>
      </>
    );
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validate}
      onSubmit={handleContinueClick}
      validateOnChange={true}
    >
      {(formikProps) => customerTypeForm(formikProps)}
    </Formik>
  );
};

ChooseCustomerType.propTypes = {};

ChooseCustomerType.defaultProps = {};

export default ChooseCustomerType;
