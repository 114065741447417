import * as types from '../actions/ActionTypes';

const initialState = {
    hasError: false
};

export default function SystemReducer(state = initialState, action) {
  switch (action.type) {
    case types.SYSTEM_ERROR:
      return {
        ...state,
        hasError: true,
        error: {
          ...action.error,
        },
      };

    case types.SYSTEM_ERROR_RESET:
      return {
        ...state,
        hasError: false,
        error: undefined,
      };

    default:
      return state;
  }
}
