import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DocumentLibrarySearch = (props) => {
  const { inputSearchValue, handleSearchKeypress } = props; 
  const [inputValue, setInputValue] = useState(inputSearchValue);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    handleSearchKeypress(event.target.value);
  };

  const clearInputValue = () => {
    setInputValue('');
    handleSearchKeypress(null);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={onFormSubmit}>
      <div className="document-library-search">
        <div className="document-library-search__wrapper">
          <i className="fas fa-search"></i>
          <input type="search" name="document-library-search" placeholder="Search by category, supplier or document" value={inputValue} onChange={handleChange}/>
          { inputValue && <i className="fal fa-times" onClick={clearInputValue}></i> }
        </div>
      </div>
    </form>
  );
};

DocumentLibrarySearch.defaultProps = {
  handleSearchKeypress : null,
  inputSearchValue: ''
};

DocumentLibrarySearch.propTypes = {
  handleSearchKeypress : PropTypes.func,
  inputSearchValue : PropTypes.string
};

export default DocumentLibrarySearch;