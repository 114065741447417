import React from 'react';
import PropTypes from 'prop-types';

const NewsArticle = (props) => {

  const { articleName,
    articleImage,
    articleTopContent,
    articleMiddleContent,
    articleBottomContent,
    articleQuote } = props;

  return (
    <>
      { articleTopContent && <div dangerouslySetInnerHTML={{ __html: articleTopContent }} /> }
      { articleImage && <div className="article-page__img">
        <img src={articleImage} alt={articleName + ' Image'} />
      </div> }
      {articleQuote && <div className="article-page__quote">
        <blockquote> {articleQuote}</blockquote>
      </div> }
      { articleMiddleContent &&  <div dangerouslySetInnerHTML={{ __html: articleMiddleContent }} /> }
      { articleBottomContent &&  <div dangerouslySetInnerHTML={{ __html: articleBottomContent }} /> }
    </>
  );
};

NewsArticle.propTypes = {
  articleName: PropTypes.string,
  articleImage: PropTypes.string,
  articleTopContent: PropTypes.string,
  articleMiddleContent: PropTypes.string,
  articleBottomContent: PropTypes.string,
  articleQuote: PropTypes.string
};

NewsArticle.defaultProps = {
  articleName: null,
  articleImage: null,
  articleTopContent: null,
  articleMiddleContent: null,
  articleBottomContent: null,
  articleQuote: null
};

export default NewsArticle;