import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import FeaturedCard from '../Card/FeaturedCard';
import ListingCard from '../Card/ListingCard';

const DealsPage = (props) => {
  const { Featured, DealsItems, pageType } = props;

  useEffect( () => {
    window.scrollTo({top: 0, left: 0});
  }, []);

  return (
    <div>
      {Featured && (
        <FeaturedCard
          variant="promotional"
          heading={Featured?.Title}
          description={Featured?.ShortDescription}
          image={Featured?.Image}
          linkUrl={`/${pageType}/article?id=${Featured?.NodeID}`}
        />
      )}
      {DealsItems?.map((item) => {
        return (
          <ListingCard
            variant="deals"
            key={item?.NodeID}
            publishedDate={item?.PublishDate}
            heading={item?.Title}
            image={item?.Image}
            linkUrl={`/${pageType}/article?id=${item?.NodeID}`}
          />
        );
      })}
    </div>
  );
};

DealsPage.propTypes = {
  Featured: PropTypes.object,
  DealsItems: PropTypes.array,
  pageType: PropTypes.string
};

DealsPage.defaultProps = {
  Featured: null,
  DealsItems: null,
  pageType: null
};

export default DealsPage;
