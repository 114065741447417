import * as types from './ActionTypes';
import { GetStoreDetails } from '../api/GetStoreDetails';
import { GetStoreDetailsByLocation } from '../api/GetStoreDetailsByLocation';

export const loadStoresByLocationSuccess = (storeDetailsByLocation) => {
    return {
        type: types.LOAD_STORE_DETAILS_BY_LOCATION,
        storeDetailsByLocation
    };
};

export const loadStoresSuccess = (storeDetails) => {
    return {
        type: types.LOAD_STORE_DETAILS,
        storeDetails
    };
};

export const loadStoresByLocation = (latitude, longitude) => async (dispatch) => {
    const stores = await GetStoreDetailsByLocation(latitude, longitude);

    dispatch(loadStoresByLocationSuccess(stores));
};

export const loadStores = () => async (dispatch, getState) => {
    const {stores: {stores}} = getState();

    /* Stores are static during user sessions, so no need to load again if already present */
    if (stores.length === 0) {
        const stores = await GetStoreDetails();
        dispatch(loadStoresSuccess(stores));
    }
};