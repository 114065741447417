import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const SectionHeader = (props) => {
  const {heading, description, seeAllUrl} = props;
  const headingOnly = description === null ? 'section-header--heading-only' : '';

  let link;
  if (seeAllUrl) {
    if (seeAllUrl.startsWith('http')) {
      link = (<a href={seeAllUrl} target="_blank" rel="noreferrer" className="text-link text-link--uppercase">SEE ALL</a>);
    } else {
      link = (<Link to={seeAllUrl} className="text-link text-link--uppercase">SEE ALL</Link>);
    }
  }

  return (
    <div className={`section-header ${headingOnly}`}>
      <div className="section-header__wrapper">
        <div className="section-header__top">
          <h3>{heading}</h3>
          {link}
        </div>
        { description && <div className="section-header__bottom">
          <p>{description}</p>
        </div> }
      </div>
    </div>
  );
};
 
SectionHeader.defaultProps = {
  heading: null,
  description: null,
  seeAllUrl: null
};

SectionHeader.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  seeAllUrl: PropTypes.string
};

export default SectionHeader;