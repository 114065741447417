import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CtaButton = (props) => {
  const { arrowIcon, type, href, text } = props;

  if (href.startsWith('http')) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={`btn btn--${type} transform-none`} >
        {text}
        {arrowIcon && <i className="fas fa-arrow-right"></i>}
      </a>
    );    
  }

  return (
    <Link to={href} className={`btn btn--${type} transform-none`} >
      {text}
      {arrowIcon && <i className="fas fa-arrow-right"></i>}
    </Link>
  );
};

CtaButton.defaultProps = {
  type: null,
  href: null,
  text: null,
  arrowIcon: false,
};

CtaButton.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  arrowIcon: PropTypes.bool,
};

export default CtaButton;
