import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalConfig } from '../Config';

export const msalInstance = new PublicClientApplication(MsalConfig);

/**
 * If the user's token is already in storage it can be immediately initialised.
*/
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  const account = msalInstance.getAllAccounts()[0];
  msalInstance.setActiveAccount(account);
}

// Post login set the active account.
msalInstance.addEventCallback(async (event) => {
  console.debug('Authentication event', event);
  
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload?.account
  ) {
    if (!msalInstance.getActiveAccount()) {
      msalInstance.setActiveAccount(event.payload.account);
    }
  }
});

/**
 * Logout current account if logged in and redirects to session ended page
 * 
 * @returns Promise<void>
 */
export async function logout() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return;
  }

  const endSessionRequest = {
    account,
    onRedirectNavigate: '/logged-out'
  };

  return msalInstance.logoutRedirect(endSessionRequest);
}

/**
 * Get access token for currently logged in account
 * 
 * @returns Promise<string> (JWT)
 */
export async function getBearerToken() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw new Error('User is not authenticated');
  }

  const request = {
    account,
    scopes: [],
    prompt: 'none'
  };

  let response = await msalInstance.acquireTokenSilent(request);  
  if (!response.idToken) {
    throw new TokenError('Token was not found');
  }

  /**
   * This should be an access token, but the id token works fine as we're not requiring any specific claims.
   */
  return response.idToken;
}

export class TokenError extends Error {
  constructor(msg) {
    super(msg);
  }
}