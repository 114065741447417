import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import AppProvider from './providers/AppProvider';
import PropTypes from 'prop-types';
import './styles/main.scss';

const App = ({msal}) => {
    return (
        <MsalProvider instance={msal}>
            <AppProvider />
        </MsalProvider>
    );
};

App.propTypes = {
    msal: PropTypes.object
  };

export default App;