import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StickyNavigation = (props) => {
  const { activeTabName } = props;
  const {CustomerType} = useSelector((state) => state.account.profile.UserSettings);
  const showLbp = CustomerType === 4;

  return (
    <div className="sticky-navigation">
      <div className="sticky-navigation__wrapper">
        <ul className="sticky-navigation__list">
          <li className={`sticky-navigation__item ${activeTabName === 'home' ? 'active' : ''}`}>
            <Link to="/">
              <i className="fas fa-home"></i>
              Home
            </Link>
          </li>
          { showLbp && <li className={`sticky-navigation__item ${activeTabName === 'lbp' ? 'active' : ''}`}>
            <Link to="/lbp-diary">
              <i className="fas fa-toolbox"></i>
              LBP Diary
            </Link>
          </li> }
          <li className={`sticky-navigation__item ${activeTabName === 'document-library' ? 'active' : ''}`}>
            <Link to="/document-library">
              <i className="fas fa-file-alt"></i>
              Resources
            </Link>
          </li>
          <li className={`sticky-navigation__item ${activeTabName === 'account-profile' ? 'active' : ''}`}>
            <Link to="/account">
              <i className="fas fa-user"></i>
              Profile
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

StickyNavigation.defaultProps = {
  activeTabName: null
};

StickyNavigation.propTypes = {
  activeTabName: PropTypes.string
};

export default StickyNavigation;