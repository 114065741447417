import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LbpActivityLogCard from './LbpActivityLogCard';
import { isEmpty } from 'ramda';
import Button from '../Form/Button';

const LbpActivityLog = (props) => {
  const { activities, archivedActivities, codewords, archivedCodewords, addAnActivity, exportActivity } =
    props;

  if (activities.length === 0 && archivedActivities.length === 0 && codewords.length === 0 && archivedCodewords.length === 0 && !exportActivity) {
    return (
      <div className="activity-log-feature activity-log-feature--no-logs">
        <p>No log available</p>
        <Button text="Add an activity" type="button" handleSubmit={addAnActivity} />
      </div>
    );
  }

  const [inputValue, setValue] = useState('active');
  const segmentedControlOnChange = (e) => {
    setValue(e.currentTarget.value);
  };

  const groupBy = function (arr, key) {
    return arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const activitiesGroup =
    inputValue === 'active'
      ? groupBy(activities, 'Type')
      : groupBy(archivedActivities, 'Type');

  const codewordsGroup =
    inputValue === 'active' ? codewords : archivedCodewords;

  return (
    <>
      {!exportActivity && archivedActivities.length !== 0 && <div className="article-page__wrapper">
        <div className="segmented-control">
          <input
            type="radio"
            name="segmented-control"
            value="active"
            id="active"
            checked={inputValue === 'active'}
            onChange={segmentedControlOnChange}
          />
          <label htmlFor="active">Active</label>

          <input
            type="radio"
            name="segmented-control"
            value="archived"
            id="archived"
            checked={inputValue === 'archived'}
            onChange={segmentedControlOnChange}
          />
          <label htmlFor="archived">Archived</label>
        </div>
      </div>}

      {isEmpty(activitiesGroup) && codewordsGroup.length === 0 && <div className="activity-log-feature activity-log-feature--no-logs"><p>No log available</p> <Button text="Add an activity" type="button" handleSubmit={addAnActivity} /></div>}

      <div className="activity-log-feature">
        {Object.keys(activitiesGroup).map((activity, index) => (
            <div key={index}>
              <h2 className="activity-log-feature__title">{activity}</h2>
              {activitiesGroup[activity].map((activity, i) => {
                return (
                  <LbpActivityLogCard
                    key={i}
                    title={activity.Title}
                    description={activity.Description}
                    date={activity.Date}
                    hours={activity.Type === 'Elective' ? activity.Hours : null}
                  />
                );
              })}
            </div>
          ))
        }
      </div>

      <div className="activity-log-feature">
        {codewordsGroup.length > 0 && <h2 className="activity-log-feature__title">Codewords</h2>}
        {codewordsGroup.map((activity, i) => (
          <LbpActivityLogCard
            key={i}
            title={activity.Title}
            description={activity.Description}
          />
        ))}
      </div>
    </>
  );
};

LbpActivityLog.propTypes = {
  activities: PropTypes.array,
  archivedActivities: PropTypes.array,
  codewords: PropTypes.array,
  archivedCodewords: PropTypes.array,
  addAnActivity: PropTypes.func,
  exportActivity: PropTypes.bool
};

LbpActivityLog.defaultProps = {
  activities: [],
  archivedActivities: [],
  codewords: [],
  archivedCodewords: [],
  addAnActivity: () => { },
  exportActivity: false
};

export default LbpActivityLog;
