import * as types from '../actions/ActionTypes';

const initialState = {
  loading: true,
  newsPage: {
    interestsSaveBtnDisable: false
  }
};

export default function NewsReducer(state = initialState, action) {

  switch (action.type) {
    case types.LOAD_NEWS_POSTS_BY_USERS_START:
      return {
        ...state,
        loading: action.loading
      };
    case types.LOAD_NEWS_POSTS_BY_USERS_COMPLETE:
      return {
        ...state,
        newsPostsByUser: action.newsPostsByUser,
        loading: false,
        newsPage: { ...state.newsPage }
      };
    case types.LOAD_NEWS_INTERESTS_BY_USER_START:
      return {
        ...state,
        loading: action.loading
      };
    case types.LOAD_NEWS_INTERESTS_BY_USER_COMPLETE:
      return {
        ...state,
        loading: false,
        ...action.newsInterestsByUser,
        newsPage: { ...state.newsPage }
      };
    case types.POST_NEWS_INTERESTS_BY_USERS_START:
      return {
        ...state,
        newsPage: { ...state.newsPage, interestsSaveBtnDisable: action.interestsSaveBtnState }
      };
    case types.POST_NEWS_INTERESTS_BY_USERS_COMPLETE:
      return {
        ...state,
        newsPage: { ...state.newsPage, interestsSaveBtnDisable: action.interestsSaveBtnState }
      };
    default:
      return state;
  }
}
