import React from 'react';

const Loader = () => {

  return (
    <>
      <div className="loading">
        <div className="loading__wrapper">
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loader;