import React from 'react';
import PropTypes from 'prop-types';
import {useField} from 'formik';

const RadioButton = (props) => {
  const { id, text, value, onValueChange, extraClass } = props;

  const [field] = useField(props);
  const { name, onChange } = field;

  return (
    <div className={`btn--toggle ${extraClass}`}>
      <input type="radio" id={id} className="sr-only" name={name} onChange={(e) => {
        onValueChange(e); 
        onChange(e);
      }} value={value} />
      <label className="btn btn--toggle-handler" htmlFor={id} >
        <span>{text}</span>
      </label>
    </div>
  );
};

RadioButton.defaultProps = {
  id: null,
  text: null,
  value: null,
  extraClass: '',
  onValueChange: (()=>{})
};

RadioButton.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  extraClass: PropTypes.string,
  value: PropTypes.number,
  onValueChange: PropTypes.func
};

export default RadioButton;