import {
  GetCategories,
  GetLibraries,
  GetMedia,
  GetMedias,
  GetDocumentSearch,
} from '../api/GetDocumentsLibrary';
import {
  PostFavourites,
  PostRecentlyViewed,
} from '../api/PostDocumentsLibrary';
import * as types from './ActionTypes';
import { loadUserDetails } from './AccountsActions';

export const loadDocumentsLibrarySuccess = (documentsByCategory) => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY,
    documentsByCategory,
  };
};

export const loadDocumentsLibraryCategoriesSuccess = (categories) => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY_CATEGORIES,
    categories,
  };
};

export const loadDocumentsLibraryMediaSuccess = (documentsByMedia) => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY_MEDIA,
    documentsByMedia,
  };
};

export const loadFavouritesAndRecentlyViewedSuccess = (
  favouritesAndRecentlyViewed,
) => {
  return {
    type: types.LOAD_FAVOURITE_AND_RECENT_VIEWED,
    favouritesAndRecentlyViewed,
  };
};

export const postDocumentsLibraryFavouritesSuccess = (favourites) => {
  return {
    type: types.POST_DOCUMENTS_LIBRARY_FAVOURIES,
    favourites,
  };
};

export const postDocumentsLibraryRecentlyViewedSuccess = (recentlyViewed) => {
  return {
    type: types.POST_DOCUMENTS_LIBRARY_RECENTLY_VIEWED,
    recentlyViewed,
  };
};

export const documentsLibrarySearchClear = () => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY_SEARCH_CLEAR,
  };
};

export const loadDocumentsLibrarySearchStart = () => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY_SEARCH_START,
  };
};

export const UItoggleDocumentsLibrarySearch = (bool) => {
  return {
    type: types.UI_DOCUMENTS_LIBRARY_SEARCH_STATUS,
    bool,
  };
};

export const loadDocumentsLibrarySearchComplete = (search) => {
  return {
    type: types.LOAD_DOCUMENTS_LIBRARY_SEARCH_COMPLETE,
    search,
  };
};

export const loadDocumentsLibrary = (id) => async (dispatch) => {
  const libraries = await GetLibraries(id);
  dispatch(loadDocumentsLibrarySuccess(libraries));
};

export const loadDocumentsLibraryCategories = () => async (dispatch, getState) => {
  if (getState().documents.categories.length === 0) { 
    const categories = await GetCategories();
    dispatch(loadDocumentsLibraryCategoriesSuccess(categories));
  }
};

export const loadDocumentsLibraryMedia = (id) => async (dispatch) => {
  const media = await GetMedia(id);
  dispatch(loadDocumentsLibraryMediaSuccess(media));
};

export const loadFavouritesAndRecentlyViewed =
  () => async (dispatch, getState) => {
    const {
      account: { profile },
    } = getState();

    const mediaFavourites = profile?.DocumentLibrary.MediaFavourites;
    const mediaRecentlyViewed = profile?.DocumentLibrary.MediaRecentlyViewed;
    if (mediaRecentlyViewed === '' && mediaFavourites === '') {
      return;
    }

    const mediaFavouritesList =
      mediaFavourites === '' ? [] : mediaFavourites.split(',');
    const mediaRecentlyViewedList =
      mediaRecentlyViewed === '' ? [] : mediaRecentlyViewed.split(',');
    const combineMedia = mediaFavouritesList
      .concat(mediaRecentlyViewedList)
      .join();

    const media = await GetMedias(combineMedia);

    const mediaFavouritesItems = mediaFavouritesList
      .filter((id) => Number(id) > 0)
      .map((id) => media.find((item) => item.NodeID === Number(id)));

    const mediaRecentlyViewedItems = mediaRecentlyViewedList
      .filter((id) => Number(id) > 0)
      .map((id) => media.find((item) => item.NodeID === Number(id)));

    mediaFavouritesList.map((id) =>
      media.find((item) => item.NodeID === Number(id)),
    );

    dispatch(
      loadFavouritesAndRecentlyViewedSuccess({
        mediaFavourites: mediaFavouritesItems,
        mediaRecentlyViewed: mediaRecentlyViewedItems,
      }),
    );
};

export const postDocumentsLibraryFavourites =
  (itemId) => async (dispatch, getState) => {
    const {
      account: { profile },
    } = getState();

    const mediaFavouritesList =
      profile.DocumentLibrary.MediaFavourites === ''
        ? []
        : profile.DocumentLibrary.MediaFavourites.split(',');

    const found = mediaFavouritesList.findIndex((id) => Number(id) === Number(itemId));
    if (found >= 0) {
      mediaFavouritesList.splice(found, 1);
    } else {
      mediaFavouritesList.push(itemId);
    }

    const mediaFavourites = mediaFavouritesList.join();
    await PostFavourites(mediaFavourites);
    const media = await GetMedias(mediaFavourites);

    dispatch(
      postDocumentsLibraryFavouritesSuccess({
        mediaFavourites: media,
      }),
    );

    // Reload all user details after save
    dispatch(loadUserDetails());
};

export const postDocumentsLibraryRecentlyViewed =
  (itemId) => async (dispatch, getState) => {
    const {
      account: { profile },
    } = getState();

    const mediaRecentlyViewed =
      profile?.DocumentLibrary?.MediaRecentlyViewed === ''
        ? []
        : profile.DocumentLibrary.MediaRecentlyViewed.split(',');
    mediaRecentlyViewed.push(itemId);
    const mediaRecentlyViewedCombined = mediaRecentlyViewed.join();

    await PostRecentlyViewed(mediaRecentlyViewedCombined);
    const media = await GetMedias(mediaRecentlyViewedCombined);

    dispatch(
      postDocumentsLibraryRecentlyViewedSuccess({
        mediaRecentlyViewed: media,
      }),
    );

    await dispatch(loadUserDetails());
};

export const loadDocumentsLibrarySearch = (searchTerm) => async (dispatch) => {
  dispatch(UItoggleDocumentsLibrarySearch(false));

  if (searchTerm.length > 2) {
    // Show loading before calling API
    dispatch(loadDocumentsLibrarySearchStart());
    dispatch(UItoggleDocumentsLibrarySearch(true));

    const search = await GetDocumentSearch(searchTerm);
    const { Results } = search;
    const documents = {
      searchTerm: searchTerm,
      ...Results,
    };
    dispatch(loadDocumentsLibrarySearchComplete(documents));
  }
};
