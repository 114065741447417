import React, { useState } from 'react';
import propTypes from 'prop-types';

const DatePicker = (props) => {
  const { label, min, max, value } = props;
  const [inputvalue, setValue] = useState(value);

  return (
    
    <div className="input-form">
      <div className="input-form__wrapper">
        <div className="input-form__field">
          <input
            className="form-input"
            onChange={(evt) => setValue(evt.target.value)}
            value={inputvalue}
            type="date"
            min={min}
            max={max}
          />
          <label className={'float form-input-label'}>{label}</label>
        </div>
        <i className="far fa-calendar-alt"></i>
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  label: null,
  max: null,
  min: null,
  value: new Date()
};

DatePicker.propTypes = {
  label: propTypes.string,
  max: propTypes.string,
  min: propTypes.string,
  value: propTypes.string
};

export default DatePicker;
