import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetDocumentSearch = async (searchTerm) => {
  const { data } = await Axios.get(
    buildRequestUrl('/api/search/documentlibrary/get'),
    {
      params: { term: searchTerm },
    },
  );

  return data;
};

export const GetCategories = async () => {
  const { data } = await Axios.get(
    buildRequestUrl('/api/content/documentlibrary/categories/get'),
  );

  return data;
};

export const GetLibraries = async (categoryId) => {
  const { data } = await Axios.get(
    buildRequestUrl('/api/content/documentlibrary/get'),
    {
      params: { categories: categoryId },
    },
  );

  return data;
};

export const GetMedia = async (mediaId) => {
  const { data } = await Axios.get(
    buildRequestUrl(
      '/api/content/documentlibrary/media/get/getbydocumentlibraryid',
    ),
    { params: { id: mediaId } },
  );

  return data;
};

export const GetMedias = async (mediaIds) => {
  const { data } = await Axios.get(
    buildRequestUrl('/api/content/documentlibrary/media/get'),
    {
      params: { ids: mediaIds },
    },
  );

  return data;
};
