import {useEffect} from 'react';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { B2CScopes } from '../Config';
import {useDispatch} from 'react-redux';
import {systemError} from '../actions/SystemActions';
import {userPostAuthentication} from '../actions/LoginActions';
import { useSelector } from 'react-redux';

export function useLogin() {
    const request = {
        scopes: B2CScopes,
        requestMode: 'query'
    };

    const dispatch = useDispatch();
    const { login, error, result } = useMsalAuthentication('silent', request);

    useEffect(() => {
      if (result?.account) {
        dispatch(userPostAuthentication(result.account));
      }
    }, [result]);
  
    useEffect(() => {
      if (error) {
        if (error instanceof InteractionRequiredAuthError) {
          login('redirect', request);          
        } else {
          dispatch(systemError({
            type: 'Login',
            message: error.errorMessage,
            page: '/error/login'
          }));
        }
      }
    }, [error]);  

    const signUpStage = useSelector( (state) => state.signUp.stage );
    const isLoggedIn = useIsAuthenticated();    

    return {isLoggedIn, signUpStage};
}