import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Progress = (props) => {
  const { percentage, variant } = props;
  const [style, setStyle] = useState({});

  useEffect(() => {

    const progressBarTimeout = setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${percentage}%`,
      };
      if (!(JSON.stringify(style) === JSON.stringify(newStyle))) {
        setStyle(newStyle);
      }
    }, 300);

    return () => {
      clearTimeout(progressBarTimeout);
    };
  }, [style]);

  return (
    <div className="progress">
      <div className={variant} style={style}></div>
    </div>
  );
};

Progress.defaultProps = {
  percentage: null,
  variant: null
};

Progress.propTypes = {
  variant: PropTypes.string,
  percentage: PropTypes.number
};

export default Progress;
