import * as types from './ActionTypes';
import { GetDealsPosts } from '../api/GetDeals';

export const loadDealsPostsByUserStart = (loading) => {
  return {
    type: types.LOAD_DEALS_POSTS_BY_USERS_START,
    loading,
  };
};

export const loadDealsPostsByUserSuccess = (dealsPostsByUser) => {
  return {
    type: types.LOAD_DEALS_POSTS_BY_USERS_COMPLETE,
    dealsPostsByUser,
  };
};

export const loadDealsPostsByUser = (trade) => async (dispatch) => {
  // Show loading before calling API
  dispatch(loadDealsPostsByUserStart(true));

  const deals = await GetDealsPosts(trade);
  dispatch(loadDealsPostsByUserSuccess({...deals}));
};
