import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDocumentsLibraryMedia, postDocumentsLibraryFavourites, postDocumentsLibraryRecentlyViewed } from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibraryCard from '../../components/DocumentsLibrary/DocumentLibraryCard';
import ActionPageLayout from '../../layouts/ActionPageLayout';

export const DocumentMedia = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    dispatch(loadDocumentsLibraryMedia(id));
    pageViewGA('Document Media');
  }, [dispatch]);

  const { documentsByMedia, mediaFavourites } = useSelector((state) => state.documents);
  const title = documentsByMedia?.PageInfo?.Title;
  const subtitle = documentsByMedia?.PageInfo?.Subtitle;
  const items = documentsByMedia.Items || [];

  const handleSelectFavourite = (id) => {
    dispatch(postDocumentsLibraryFavourites(id));
  };

  const handleRecentlyViewed = (id, linkUrl) => {
    dispatch(postDocumentsLibraryRecentlyViewed(id, linkUrl));
  };

  const cards = items.map((card) => {
    const isFavourite =
      mediaFavourites &&
      mediaFavourites.findIndex((item) => item.NodeID === card.NodeID) >= 0;

    const props = {
      title: card.Title,
      id: card.NodeID,
      isFullWidth: true,
      linkUrl: card.Link,
      iconType: card.Type,
      isFavourite,
      handleSelectFavourite,
      handleRecentlyViewed
    };

    return <DocumentLibraryCard key={card.NodeID} {...props} />;
  });

  return (
    <ActionPageLayout
      pageTitle={title}
      pageDescription={subtitle}
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <div className="cards-list cards-list--stack">{cards}</div>
    </ActionPageLayout>
  );
};

DocumentMedia.propTypes = {};
DocumentMedia.defaultProps = {};
