import * as types from '../actions/ActionTypes';

const defaultState = {
  categories: [],
  documentsByCategory: [],
  documentsByMedia: [],
  mediaFavourite: [],
  mediaRecentlyViewed: [],
  showDocumentSearchPage: false,
  search: {}
};

export default function DocumentsLibraryReducer(state = defaultState, action) {
  switch (action.type) {
    case types.LOAD_DOCUMENTS_LIBRARY_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };

    case types.LOAD_DOCUMENTS_LIBRARY:
      return {
        ...state,
        documentsByCategory: action.documentsByCategory
      };

    case types.UI_DOCUMENTS_LIBRARY_SEARCH_STATUS:
      return {
        ...state,
        showDocumentSearchPage: action.bool
      };

    case types.LOAD_DOCUMENTS_LIBRARY_SEARCH_START:
      return {
        ...state,
        fetchingSearchResult: true
      };

    case types.LOAD_DOCUMENTS_LIBRARY_SEARCH_COMPLETE:
      return {
        ...state,
        search: action.search,
        fetchingSearchResult: false
      };

    case types.LOAD_DOCUMENTS_LIBRARY_SEARCH_CLEAR:
      return {
        ...state,
        search: {},
        showDocumentSearchPage: false,
        fetchingSearchResult: false
      };      

    case types.LOAD_DOCUMENTS_LIBRARY_MEDIA:
      return {
        ...state,
        documentsByMedia: action.documentsByMedia
      };

    case types.LOAD_FAVOURITE_AND_RECENT_VIEWED:
      return {
        ...state,
        ...action.favouritesAndRecentlyViewed
      };

    case types.POST_DOCUMENTS_LIBRARY_FAVOURIES:
      return {
        ...state,
        ...action.favourites
      };

    case types.POST_DOCUMENTS_LIBRARY_RECENTLY_VIEWED:
      return {
        ...state,
        ...action.recentlyViewed
      };

    default:
      return state;
  }
}
