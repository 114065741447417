import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  tradeClubDetails : {
    TradeClub : {},
    popularRewards: {},
    userDetails: {}
  }
};

export default function TradeClubReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_TRADE_CLUB_DETAILS_START:
      return {
        ...state,
        loading: true
      };
    case types.LOAD_TRADE_CLUB_DETAILS_COMPLETE:
      return {
        ...state,
        tradeClubDetails: action.tradeClubDetails,
        loading: false
      };
    default:
      return state;
  }
}
