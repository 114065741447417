import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeadPage from '../../components/HeadPage';
import Geocode from 'react-geocode';
import { Link } from 'react-router-dom';
import { GoogleApiKey } from '../../Config';

const LocateStore = (props) => { 
  const { title, HandleStoreSearch, storeDetails, handleStoreChange } = props;
  var [searchInputValue, setSeatchInputValue] = useState('');

  useEffect( () => {
    window.scrollTo({top: 0, left: 0});
  });

  const renderSearchResultsLists = (storeDetails) => {
    let stores = [];
    storeDetails.map((item, i) => {
      stores.push(
        <li className="" key={i}>
          <Link
            to={`/account/local-itm/?id=${item.NodeID}`}
            className="locate-itm__link"
            onClick={() => handleStoreChange(item.NodeID)}
          >
            <h4 className="store-name">{item.Name}</h4>
            <p className="store-address small-text">
              {item.NoHTMLPhysicalAddress}
            </p>
          </Link>
        </li>
      );
    });
    return stores;
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      Geocode.setApiKey(GoogleApiKey);
      Geocode.setRegion('NZ');
      try {
        if(!searchInputValue.includes('ITM')){
          searchInputValue = searchInputValue + ' ITM';
        }
        const response = await Geocode.fromAddress(searchInputValue);
        if (response?.results?.length <= 0) {
          return;
        }

        const { lat, lng } = response.results[0].geometry.location;
        if (HandleStoreSearch) {
          HandleStoreSearch(lat, lng);   
        }   
      } catch (error) {
        console.error(`Failed to get geolocation for ${searchInputValue}`, {error});
      }
    }
  };

  //locate store ends here
  return (
    <div className="locate-itm">
      <div className="locate-itm__wrapper">
        <div className="locate-itm__heading">
          <HeadPage
            pageTitle={title}
            hasBackButton={true}
            titleBgColor={'white'}
          />
        </div>
        <div className="locate-itm__description">
          <p>Find your nearest ITM location.</p>
        </div>
        <div className="locate-itm__locate-store">
          <div>
            <input
              type="text"
              placeholder="Store search"
              value={searchInputValue}
              onChange={(evt) => setSeatchInputValue(evt.target.value)}
              onKeyUp={handleKeyPress}
            />
          </div>
          <div className="locate-itm__icon">
            <i className="fas fa-location"></i>
          </div>
        </div>
        <div className="locate-itm__store-list">
          <ul>{renderSearchResultsLists(storeDetails)}</ul>
        </div>
      </div>
    </div>
  );
};

LocateStore.propTypes = {
  HandleStoreSearch: PropTypes.func,
  storeDetails: PropTypes.array,
  title: PropTypes.string,
  handleStoreChange: PropTypes.func
};

LocateStore.defaultProps = {
  HandleStoreSearch: () => {},
  storeDetails: [],
  title: null,
  handleStoreChange: () => {},
};

export default LocateStore;
