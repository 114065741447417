import React from 'react';
import PropTypes from 'prop-types';

const LbpDiaryAction = (props) => {
  const { handleClick, title, description } = props;

  return (
    <button onClick={handleClick} className="lbp-diary-action">
      <div className="lbp-diary-action__wrapper">
        <h4 className="lbp-diary-action__title">{title}</h4>
        <p className="lbp-diary-action__description">{description}</p>
        <div className="lbp-diary-action__corner"></div>
      </div>
    </button>
  );
};

LbpDiaryAction.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  handleClick: PropTypes.func,
};

LbpDiaryAction.defaultProps = {
  title: '',
  description: '',
  handleClick: () => {},
};

export default LbpDiaryAction;
