import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { msalInstance } from './services/Msal';
import { Environment } from './Config';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = document.getElementById('root');

if (Environment === 'production') {
  console.debug = () => {};
}


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App msal={msalInstance} />
    </Router>
  </React.StrictMode>,
  root,
);

serviceWorkerRegistration.register();