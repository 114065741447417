import * as types from './ActionTypes';
import { GetNewsPosts } from '../api/GetNews';
import { GetDealsPosts } from '../api/GetDeals';
import { GetRewards } from '../api/GetTradeClub';

export const loadHomePageStart = (loading) => {
    return {
        type: types.LOAD_HOME_PAGE_START,
        loading
    };
};

export const loadHomePageSuccess = (homePage) => {
    return {
        type: types.LOAD_HOME_PAGE_COMPLETE,
        homePage
    };
};

export const loadHomePage = () => async (dispatch, getState) => {
    dispatch(loadHomePageStart(true));

    const homePage = {
        newsPosts: {},
        tradeDeals: {},
        latestOffers: {},
        popularRewards: {}
    };    

    const {account: {profile}} = getState();

    const memberNo = profile.TradeClub.MemberNo;
    const storeId = profile.Store.NodeID;

    if (profile.News.NewsCategories?.length > 0) {
        homePage.newsPosts = await GetNewsPosts(profile.News.NewsCategories, storeId);
    }

    const deals = await GetDealsPosts('Trade,DIY', true);
    const { Items } = deals;

    homePage.tradeDeals = Items.filter((i) => i.Categories !== 'DIY');
    homePage.latestOffers = Items.filter((i) => i.Categories !== 'Trade');

    if (memberNo) {
        homePage.popularRewards = await GetRewards();
    }    

    dispatch(loadHomePageSuccess(homePage));
};
