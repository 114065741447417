import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/TradeClubActions';
import { getCookie, setCookie } from '../util/cookie';
import { pageViewGA } from '../services/GoogleAnalytics';
import Loader from '../components/Loader';
import TradeClubCard from '../components/TradeClub/TradeClubCard';
import ClubCard from '../components/Card/TradeClubCard';
import SectionHeader from '../components/SectionHeader';
import CardSlider from '../components/CardSlider';
import TradeCard from '../components/Card/TradeCard';
import StoreDetail from '../components/StoreDetail';
import StickyNavigation from '../components/StickyNavigation';

class TradeClubContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTradeClubCardClosed: false,
    };
    this.handleButtonClose = this.handleButtonClose.bind(this);
  }

  componentDidMount() {
    const {
      actions,
    } = this.props;

    if (getCookie('hideTradeClubCard') === 'true') {
      this.setState({ isTradeClubCardClosed: true });
    }

    pageViewGA('Trade club');
    actions.loadTradeClubDetails();
  }

  handleButtonClose() {
    setCookie('hideTradeClubCard', 'true', '315360000');
    this.setState({ isTradeClubCardClosed: true });
  }

  renderTradeclubCard() {
    const {
      profile: { TradeClub },
    } = this.props;

    const TotalPoints =
      TradeClub && TradeClub.TotalPoints ? TradeClub.TotalPoints : null;
    const LastUpdatedOn =
      TradeClub && TradeClub.LastUpdatedOn ? TradeClub.LastUpdatedOn : null;
    const ExpiringPointsForThisMonth =
      TradeClub && TradeClub.ExpiringPointsForThisMonth
        ? TradeClub.ExpiringPointsForThisMonth
        : 0;
    const EarnedPointsForThisMonth =
      TradeClub && TradeClub.EarnedPointsForThisMonth
        ? TradeClub.EarnedPointsForThisMonth
        : 0;
    const MemberNo =
      TradeClub && TradeClub.MemberNo ? TradeClub.MemberNo : null;

    /*
    let HandleTradeClubLink = (cardNumber) => {
      const { actions } = this.props;

      const updatedUserDetails = {
        TradeClub: {
          MemberNo: Number(cardNumber),
        },
      };

      if (cardNumber) {
        actions.updateUserDetails(updatedUserDetails);
      }
    };
    */

    const isTradeClubMember = MemberNo !== null && MemberNo !== '';

    if (isTradeClubMember) {
      return (
        <TradeClubCard
          totalPoints={TotalPoints}
          EarnedPointsForThisMonth={EarnedPointsForThisMonth}
          ExpiringPointsForThisMonth={ExpiringPointsForThisMonth}
          LastUpdatedOn={LastUpdatedOn}
        />
      );
    } else if (!this.state.isTradeClubCardClosed) {
      return (
        <ClubCard
          headingMain="have an itm"
          headingSub="Trade club number?"
          description="Link your Trade club number."
          handleButtonClose={this.handleButtonClose}
        />
      );
    }
  }

  renderSectionHeader() {
    const { popularRewards } = this.props;

    const heading =
      popularRewards.PageInfo && popularRewards.PageInfo.Title
        ? popularRewards.PageInfo.Title
        : null;
    const Subtitle =
      popularRewards.PageInfo && popularRewards.PageInfo.Subtitle
        ? popularRewards.PageInfo.Subtitle
        : null;
    const Link =
      popularRewards.PageInfo && popularRewards.PageInfo.Link
        ? popularRewards.PageInfo.Link
        : null;

    return (
      <SectionHeader
        heading={heading}
        description={Subtitle}
        seeAllUrl={Link}
      />
    );
  }

  renderRewardsSlider() {
    const { popularRewards } = this.props;
    const items =
      popularRewards && popularRewards.Items ? popularRewards.Items : [];

    const cards = items.map((card) => (
      <TradeCard
        key={card.NodeID}
        image={card.Image}
        headingOne={card.Line1Title}
        headingTwo={card.Line2Title}
        description={card.Description}
        linkText={`${card.Points} Points Redeem Now.`}
        linkUrl={card.CTALink}
      />
    ));
    return <CardSlider>{cards}</CardSlider>;
  }

  renderStoreDetail() {
    const {
      user, profile
    } = this.props;

    const storeName = profile?.Store?.Name;
    const userName = user.firstName;
    const storeId = profile?.Store.NodeID;

    return <StoreDetail storeName={storeName} storeId={storeId} userName={userName} />;
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          {this.renderStoreDetail()}
          {this.renderTradeclubCard()}
          {this.renderSectionHeader()}
          {this.renderRewardsSlider()}
          <StickyNavigation activeTabName="home" />
        </>
      );
    }
  }
}

TradeClubContainer.propTypes = {
  TradeClub: PropTypes.object,
  popularRewards: PropTypes.object,
  user: PropTypes.object,
  profile: PropTypes.object,
  loading: PropTypes.bool,
  actions: PropTypes.object
};

TradeClubContainer.defaultProps = {
  TradeClub: {},
  popularRewards: {},
  user: {},
};

const mapStateToProps = (state) => {
  const {
    tradeClub: {
      tradeClubDetails: { TradeClub, popularRewards },
      loading,
    },
    account: {profile},
    auth: {user}
  } = state;

  return {
    TradeClub,
    popularRewards,
    loading,
    user,
    profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeClubContainer);
