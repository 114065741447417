import * as types from '../actions/ActionTypes';

const initialState = {};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_USER_LOGIN_ERROR:
      return {
        ...state,
        error: {
          ...action.error,
        },
      };

    case types.AUTH_USER_LOGGED_IN:
      return {
        ...state,
        user: {
          id: action.account.localAccountId,
          firstName: action.account?.idTokenClaims.given_name,
          familyName: action.account?.idTokenClaims.family_name,
          email: action.account.username,
        },
      };

    case types.AUTH_USER_LOGOUT:
      return {

      };

    default:
      return state;
  }
}
