import history from '../history';
import * as types from './ActionTypes';
import { postPriorityCardNumber } from '../api/PostPriority';
import { ToastNotification } from '../components/ToastNotification';
import {loadUserDetails} from './AccountsActions';

export const updatePriorityCardNumberByUserStart = (loading) => {
  return {
    type: types.POST_PRIORITY_CARD_DETAILS_START,
    loading,
  };
};

export const updatePriorityCardNumberByUserSuccess = (loading) => {
  return {
    type: types.POST_PRIORITY_CARD_DETAILS_COMPLETE,
    loading,
  };
};

export const updatePriorityCardNumberByUser =
  (cardNumber) => async (dispatch) => {
    dispatch(updatePriorityCardNumberByUserStart(true));

    const goBack = () => {
      dispatch(updatePriorityCardNumberByUserSuccess(false));
      history.goBack();
    };

    try {
      await postPriorityCardNumber(cardNumber);
      dispatch(loadUserDetails());

      ToastNotification(
        'success',
        ' Got it!',
        'This card number has been linked to your account',
        3000,
        goBack,
      );
    } catch (ex) {
      ToastNotification(
        'error',
        'Something went wrong',
        'Please check your internet and try again.',
        3000,
        goBack,
      );
    }
  };
