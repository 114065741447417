import * as types from './ActionTypes';
import { GetRewards } from '../api/GetTradeClub';

export const loadTradeClubDetailsStart = (loading) => {
    return {
        type: types.LOAD_TRADE_CLUB_DETAILS_START,
        loading
    };
};

export const loadTradeClubDetailsSuccess = (tradeClubDetails) => {
    return {
        type: types.LOAD_TRADE_CLUB_DETAILS_COMPLETE,
        tradeClubDetails
    };
};

export const loadTradeClubDetails = () => async (dispatch) => {
    // Show loading before calling API
    dispatch(loadTradeClubDetailsStart(true));

    const rewards = await GetRewards();
    dispatch(loadTradeClubDetailsSuccess({...rewards}));
};


