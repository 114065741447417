import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/DealsActions';
import { formatDate } from '../util/dates';
import { pageViewGA } from '../services/GoogleAnalytics';
import ArticleInfo from '../components/ArticleInfo';
import Loader from '../components/Loader';
import HeadPage from '../components/HeadPage';
import DealsArticle from '../components/Deals/DealsArticle';
import DealsPage from '../components/Deals/DealsPage';
import StickyNavigation from '../components/StickyNavigation';

class DealsContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { actions, dealsPage: { updatePosts }, pageType } = this.props;
    
    if (updatePosts) {
      let category = '';
      if (pageType === 'deals') {
        pageViewGA('Deals');
        category = 'Trade';
      } else {
        pageViewGA('DIY');
        category = 'DIY';
      }
      actions.loadDealsPostsByUser(category);
    }
  }

  renderPosts() {
    const { dealsPostsByUser, loading, pageType } = this.props;
    const Title = dealsPostsByUser.PageInfo && dealsPostsByUser.PageInfo.Title ? dealsPostsByUser.PageInfo.Title: null;
    const items = dealsPostsByUser.Items ? dealsPostsByUser.Items : [];

    const Featured = items.find((card) => card?.Featured === true);
    const DealsItems = items.filter((card) => card?.NodeID !== Featured?.NodeID);

    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <div className="head-page__nav">
            <HeadPage pageTitle={Title} hasBackButton={true} />
          </div>
          <DealsPage
            pageType={pageType}
            Featured={Featured}
            DealsItems={DealsItems}
          />
        </>
      );
    }
  }

  renderDealsArticle() {
    const { dealsPostsByUser, loading } = this.props;
    const items = dealsPostsByUser.Items ? dealsPostsByUser.Items : [];
    var url = new URL(window.location.href);
    var idParamValue = Number(url.searchParams.get('id'));

    var article = items.filter((post) => post.NodeID === idParamValue);

    if (article.length) {
      var {
        Title,
        Image,
        Description,
        BottomContent,
        AuthorName,
        PublishDate,
        ContentQuote,
      } = article[0];
    }

    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <div className="article-page">
            <HeadPage
              pageTitle={Title}
              hasBackButton={true}
              titleBgColor={'yellow'}
            />
            <div className="article-page__wrapper">
              {AuthorName && PublishDate && (
                <ArticleInfo
                  author={AuthorName}
                  publishedDate={formatDate(PublishDate)}
                />
              )}
              <DealsArticle
                articleName={AuthorName}
                articleImage={Image}
                articleMiddleContent={Description}
                articleBottomContent={BottomContent}
                articleQuote={ContentQuote}
              />
            </div>
          </div>
        </>
      );
    }
  }

  render() {
    const { pageType } = this.props;

    return (
      <>
        {
          /* initial page of deals/DIY posts */
          (pageType === 'deals' || pageType === 'DIY') && this.renderPosts()
        }
        {
          /* News article page */
          pageType === 'article' && this.renderDealsArticle()
        }
         <StickyNavigation activeTabName="home" />
      </>
    );
  }
}
DealsContainer.propTypes = {
  dealsPostsByUser: PropTypes.object,
  pageType: PropTypes.string,
  actions: PropTypes.object,
  dealsPage: PropTypes.object,
  loading: PropTypes.bool,
};

DealsContainer.defaultProps = {
  dealsPostsByUser: {},
  loading: false
};

const mapStateToProps = (state) => {
  const {
    deals: {
      dealsPostsByUser,
      loading,
      dealsPage,
    },
  } = state;
  return {
    dealsPostsByUser,
    loading,
    dealsPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsContainer);
