import React, { PureComponent } from 'react';
import { getAllCookies } from '../util/cookie';

class DebugContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cookies: {}
    };
  }

  componentDidMount() {
    this.setState({cookies : getAllCookies() });
  }

  render() {
    const { cookies } = this.state;
    return (
      <div className="horizontal-spacing">
        
          {Object.keys(cookies).map(key => 
              <>
                <h5 className="m-0 yellow-highlight" key={key}>{key}</h5>
                <h6 className="break-all" key={key + '1'}>{cookies[key]}</h6>
                </>
          )}
        
      </div>
    );
  }
}

export default DebugContainer;
