import React from 'react';
import PropTypes from 'prop-types';

import FeaturedCard from '../Card/FeaturedCard';
import ListingCard from '../Card/ListingCard';


const NewsPage = (props) => {
  const { cards } = props;

  const featuredPost = cards.find((card)=> (card?.Featured === true));
  const newsItems = cards.filter((card)=> (card?.NodeID !== featuredPost?.NodeID));

  return (
    <div>
      {featuredPost && <FeaturedCard variant="news" heading={featuredPost?.ArticleName} description={featuredPost?.ArticleTitle} image={featuredPost?.HeroImage} linkUrl={`/news/article?id=${featuredPost?.NodeID}`}/>}
      {newsItems?.map((item) => (<ListingCard variant="news" key={item?.NodeID} publishedDate={item?.PublishDate} heading={item?.ArticleName} image={item?.HeroImage} linkUrl={`/news/article?id=${item?.NodeID}`} /> ))}
    </div>
  );
};

NewsPage.propTypes = {
  cards:PropTypes.array.isRequired
};

NewsPage.defaultProps = {
  cards: []
};

export default NewsPage;
