import * as types from './ActionTypes';

export const systemError = (error) => {
    return {
        type: types.SYSTEM_ERROR,
        error
    };
};

export const systemErrorClear = () => {
    return {
        type: types.SYSTEM_ERROR_RESET
    };
};

