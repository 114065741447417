import React, { useState, useEffect } from 'react';
import Geocode from 'react-geocode';
import { Link } from 'react-router-dom';
import {GoogleApiKey} from '../../Config';
import { useDispatch, useSelector } from 'react-redux';
import {loadStores, loadStoresByLocation} from '../../actions/StoresActions';

const StoresSearch = () => {
  const [searchInputValue, setSearchInputValue] = useState(''); 
  const dispatch = useDispatch();
  let stores = useSelector( (state) => state.stores.stores);
  const storesByLocation = useSelector( (state) => state.stores.storesByLocation);
  
  useEffect( () => {
    dispatch(loadStores());
  }, [dispatch]);

  if (storesByLocation?.length > 0) {
    stores = storesByLocation;
  }

  const renderSearchResultsLists = (stores) => {
    return stores.map( (item) => 
      (
        <li key={item.NodeID}>
          <Link to={`/sign-up/closest-store?id=${item.NodeID}`}>
            <h4 className="store-name">{item.Name}</h4>
            <p className="store-address small-text">{item.NoHTMLPhysicalAddress}</p>
          </Link>
        </li>        
      )
    );
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      Geocode.setApiKey(GoogleApiKey);
      Geocode.setRegion('NZ');
      const response = await Geocode.fromAddress(searchInputValue);

      const { lat, lng } = response.results[0].geometry.location;
      dispatch(loadStoresByLocation(lat, lng));
    }
  };

  return (
    <div className="store-search horizontal-spacing">
      <div className="store-search__wrapper">
        <div className="store-search__search-input">
          <i className="fas fa-location"></i>
          <input type="text" placeholder="Store search" value={searchInputValue} onChange={(evt) => setSearchInputValue(evt.target.value)} onKeyUp={handleKeyPress} />
        </div>
        <ul className="store-search__search-results">
          {renderSearchResultsLists(stores)}
        </ul>
      </div>
    </div>
  );
};

StoresSearch.propTypes = {

};

StoresSearch.defaultProps = {

};

export default StoresSearch;