import {store} from '../stores/main';

window.dataLayer = window.dataLayer || [];

/**
 * Push page view to google analytics
 * 
 * @param {*} pageTitle 
 * @returns 
 */
export const pageViewGA = (pageTitle) => {
  const {account: {profile}, auth: {user}} = store.getState();

  const event = {
    event: 'pageView',
    pageTitle: pageTitle,
    pagePath: window.location.pathname + window.location.hash,
    customerType: profile?.UserSettings?.CustomerType,
    selectedStore: profile?.Store?.Name,
    userId: user?.id
  };

  /**
   * Page views are triggered from react component the current lifecycle of these components may
   * cause multiple page view triggers. 
   * 
   * If the last event is the same as the current it is discarded.
   */
  if (window.dataLayer.length > 0) {
    const last = window.dataLayer[window.dataLayer.length - 1];
    const isDuplicate = Object.keys(event).reduce((p, c) => p && event[c] === last[c], true);
    if (isDuplicate) {
      return;
    }
  }

  window.dataLayer.push(event);
};
