import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetStoreDetailsByLocation = async (latitude, longitude) => {
  const { data } = await Axios.get(buildRequestUrl('/api/content/stores/get'), {
    params: { latitude, longitude },
  });

  return data;
};
