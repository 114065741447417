import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import SimplePageLayout from '../../layouts/SimplePageLayout';
import Button from '../../components/Form/Button';

export const UnauthorisedError = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
        onRedirectNavigate: () => false
    });
  }, []);

  const onButtonClick = () => {
    // Invoke a full page refresh
    location.href = '/';
  };

  return (
    <SimplePageLayout
      pageTitle="Oops"
      pageDescription="Something went wrong..."
      hasBackButton={false}
    >
      <p className="SystemError__description">It looks like you session may have expired. You can try logging in again.</p>
      <div>
        <Button
          text="Sign in again"
          type="primary"
          handleSubmit={onButtonClick}
        />
      </div>
    </SimplePageLayout>
  );
};

UnauthorisedError.propTypes = {};

UnauthorisedError.defaultProps = {};
