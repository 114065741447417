import React from 'react';
import propTypes from 'prop-types';
import {useField} from 'formik';

const TextBox = (props) => {
  const { label, rows, readOnly } = props;
  const [field, meta] = useField(props);
  const { value, onChange, onBlur, name } = field;

  return (
    <div className="input-form">
      <div className="input-form__wrapper">
        <div className="floating-label">
          {rows === null ? (
            <input
              className={`form-input ${ (meta.error ? 'invalid' : 'valid') }`}
              name={name}
              value={value}
              readOnly={readOnly}
              disabled={readOnly}
              onChange={onChange}
              onBlur={onBlur}
            />
          ) : (
            <textarea
              className="form-input"
              rows={rows}
              readOnly={readOnly}
              onChange={onChange}
            />
          )}
          {label ? (
            <label
              className={`${value !== undefined && value.length !== 0 && 'float'} form-input-label`}
            >
              {label}
            </label>
          ) : null}
        </div>
      </div>
    </div>
  );
};
TextBox.defaultProps = {
  label: null,
  rows: null,
  value: '',
  onBlur: null,
  onChange: null,
  readOnly: false
};

TextBox.propTypes = {
  label: propTypes.string,
  rows: propTypes.string,
  value: propTypes.string,
  onBlur: propTypes.func,
  onChange: propTypes.func,
  readOnly: propTypes.bool
};

export default TextBox;
