import React from 'react';
import PropTypes from 'prop-types';
import HeadPage from '../../components/HeadPage';
import Button from '../../components/Form/Button';
import { useHistory } from 'react-router-dom';

const LocalITM = (props) => {
  const { title, stores } = props;
  var url = new URL(window.location.href);
  var id = Number(url.searchParams.get('id'));
  const store = stores?.find((element) => element.NodeID == id);

  const history = useHistory();

  if (!store) {
    history.push('/');
  }

  return (
    <div className="local-itm">
      <div className="local-itm__wrapper">
        <div className="local-itm__heading">
          <HeadPage
            pageTitle={title}
            hasBackButton={true}
            titleBgColor={'white'}
          />
        </div>
        <div className="local-itm__banner">
          <img src={store.Image} alt=""/>
        </div>
        <div className="local-itm__sub-heading">
          <h3 dangerouslySetInnerHTML={{ __html: store?.Name }}></h3>
        </div>

        <div className="local-itm__address-postal">
          <div className="local-itm__address">
            <h4>Address</h4>
            <p dangerouslySetInnerHTML={{ __html: store?.PhysicalAddress }}></p>
          </div>
          <div className="local-itm__postal">
            <h4>Postal</h4>
            <p dangerouslySetInnerHTML={{ __html: store?.PostalAddress }}></p>
          </div>
        </div>
        <div className="local-itm__contact">
          <h4>Phone</h4>
          <p>{store?.PhoneNumber}</p>
        </div>
        <div className="local-itm__opening-hours">
          <h4>Opening hours</h4>
          <div className="local-itm__timings">
            <p dangerouslySetInnerHTML={{ __html: store?.OpeningHours }}></p>
          </div>
        </div>
        <div className="local-itm__btn">
            <Button
              text="Select a different store"
              handleSubmit={() => {
                history.push('/account/locate-store');
              }}
            />          
        </div>
      </div>
    </div>
  );
};

LocalITM.propTypes = {
  title: PropTypes.string,
  stores: PropTypes.array,
};

LocalITM.defaultProps = {
  title: null,
  stores: [],
};
export default LocalITM;
