import React from 'react';
import HeadPage from '../HeadPage';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CtaButton from '../Button/CtaButton';

const TradeClubSettings = (props) => {
  const { title, tradeClubNumber } = props;

  return (
    <div className="TradeClubSettings">
      <div className="TradeClubSettings__wrapper-main">
        <HeadPage
          pageTitle={title}
          hasBackButton={true}
          titleBgColor={'white'}
        />
        {tradeClubNumber && (
          <>
            <div className="TradeClubSettings__description-main">
              <p>Edit your Trade Club account details below:</p>
            </div>
            <div className="TradeClubSettings__description-sub">
              <p>Currently linked to account number </p>
              <p className="card-number">{tradeClubNumber}</p>
              <p>
                If that&apos;s not you. You can update the Trade Club account
                number <Link to="/account/trade-club-link">here.</Link>
              </p>
            </div>
          </>
        )}

        {!tradeClubNumber && (
          <>
            <div className="TradeClubSettings__description-main">
              <p>
                You do not have a Trade Club number linked to this account. Your linked account can be used directly from this application.
              </p>
            </div>

            <CtaButton text="Link my account" href='/account/trade-club-link' />
          </>
        )}
      </div>
    </div>
  );
};
TradeClubSettings.defaultProps = {
  title: null,
  tradeClubNumber: null,
};

TradeClubSettings.propTypes = {
  title: propTypes.string,
  tradeClubNumber: propTypes.string,
};

export default TradeClubSettings;
