import * as types from '../actions/ActionTypes';

const initialState = {
  stage: 'unknown',
};

export default function SignupReducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_UP_USER_STAGE_CHANGE:
      return {
        ...state,
        stage: action.status
      };

      case types.SIGN_UP_USER_PROFILE:
        return {
          ...state,
          ...action.profile,
          ...action.stage
        };
    default:
      return state;
  }
}
