import React from 'react';
import propTypes from 'prop-types';
import {useField} from 'formik';

const SelectBoxV2 = (props) => {
  const { label, options } = props;
  const [ field ] = useField(props);
  const { value, onChange, name } = field;

  const renderOptions = (optionsArray) => {
    const options = [];
    optionsArray?.map((item, i) => {
      options.push(<option key={i} value={item.value}>{item.name}</option>);
    });
    return options;
  };

  return (
    <div className="input-form">
      <div className="input-form__wrapper">
        <div className="input-form__field">
          <select
            className="form-input form-input--select"
            name={name}
            value={value}
            onChange={onChange}
          >
            {renderOptions(options)}
          </select>
          <label className={'float form-input-label'}>{label}</label>
        </div>
        <i className="fas fa-chevron-down"></i>
      </div>
    </div>
  );
};

SelectBoxV2.defaultProps = {
  label: null,
  options: [],
};

SelectBoxV2.propTypes = {
  label: propTypes.string,
  options: propTypes.array,
};

export default SelectBoxV2;