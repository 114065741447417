import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetRewards = async () => {
  const {data} = await Axios.get(buildRequestUrl('/api/content/tradeclub/rewards/get'));

  return data;
};

export const GetStoreDetails = async () => {
  const {data} = await Axios.get(buildRequestUrl('/api/content/stores/get'));

  return data;
};
