import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadFavouritesAndRecentlyViewed,
  loadDocumentsLibraryCategories,
  documentsLibrarySearchClear,
  loadDocumentsLibrarySearch,
} from '../../actions/DocumentsLibraryActions';
import { pageViewGA } from '../../services/GoogleAnalytics';
import DocumentLibrarySearch from '../../components/DocumentsLibrary/DocumentLibrarySearch';
import DocumentSearchResults from '../../components/DocumentsLibrary/DocumentSearchResults';
import DocumentLibraryFeatures from '../../components/DocumentsLibrary/DocumentLibraryFeatures';
import ActionPageLayout from '../../layouts/ActionPageLayout';
import { debounce } from '../../util/misc';

export const Resources = () => {
  const dispatch = useDispatch();

  const {
    search,
    showDocumentSearchPage,
    fetchingSearchResult,
    categories,
    mediaFavourites,
    mediaRecentlyViewed,
  } = useSelector((state) => state.documents);

  useEffect(() => {
    dispatch(loadFavouritesAndRecentlyViewed());
    dispatch(loadDocumentsLibraryCategories());
    pageViewGA('Document Library');
    window.scrollTo({top: 0, left: 0});
  }, [dispatch]);

  const searchInputValue = search?.searchTerm || '';

  const handleSearchKeypress = debounce((searchText) => {
    if (searchText === null) {
      dispatch(documentsLibrarySearchClear());
    }
    else {
      dispatch(loadDocumentsLibrarySearch(searchText));
    }
  }, 1000);

  return (
    <ActionPageLayout
      pageTitle="Document library"
      mainHeading={true}
      hasBackButton={true}
      activeNavigationTab="document-library"
    >
      <DocumentLibrarySearch
        inputSearchValue={searchInputValue}
        handleSearchKeypress={handleSearchKeypress}
      />
      {showDocumentSearchPage && (
        <DocumentSearchResults
          search={search}
          fetchingSearchResult={fetchingSearchResult}
        />
      )}
      {!showDocumentSearchPage && (
        <DocumentLibraryFeatures
          categories={categories}
          mediaFavourites={mediaFavourites}
          mediaRecentlyViewed={mediaRecentlyViewed}
        />
      )}
    </ActionPageLayout>
  );
};

Resources.propTypes = {};
Resources.defaultProps = {};
