export const formatDate = (str) => {
  var date = new Date(str);

  date = `${date.toLocaleDateString(undefined, {
    day: '2-digit',
  })} ${date.toLocaleDateString(undefined, {
    month: 'long',
  })} ${date.toLocaleDateString(undefined, { year: 'numeric' })}`;

  return date;
};

export const formatDateForDatePicker = (date = new Date()) => {
  if (typeof date === 'string' && date !== '') {
    let tempDate = date.split('/');
    date = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
  } else {
    date = new Date();
  }

  var d = new Date(date),
    month = d.getMonth() + 1,
    day = d.getDate(),
    year = d.getFullYear();

  if (month.toString().length < 2) {
    month = `0${month}`;
  }

  if (day.toString().length < 2) {
    day = `0${day}`;
  }
  
  return [year, month, day].join('-');
};
