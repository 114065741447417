import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  homePage: {
    newsPosts: {},
    tradeDeals: [],
    latestOffers: [],
    popularRewards: {}
  }
};

export default function HomeReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_HOME_PAGE_START:
      return {
        ...state, loading: true
      };
    case types.LOAD_HOME_PAGE_COMPLETE:
      return {
        ...state, loading: false, homePage : { ...action.homePage }
      };
    default:
      return state;
  }
}
