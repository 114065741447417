import Axios from 'axios';
import { buildRequestUrl } from '../Config';

export const GetDealsPosts = async (categories, homepage = '') => {
  const { data } = await Axios.get(buildRequestUrl('/api/content/deals/get'), {
    params: { topn: 10, categories, homepage },
  });

  return data;
};
