import {
  GetBasics,
  GetActivities,
  GetCodewords,
  GetCodewordsByUser,
  GetLbpLicenceClasses,
} from '../api/GetLbp';
import * as types from './ActionTypes';
import { PostLbpUserProfile, PostLbpAddActivity } from '../api/PostLbp';
import { loadUserDetails } from './AccountsActions';

export const LbpLoading = (loading) => {
  return {
    type: types.LOAD_LBP_START,
    loading,
  };
};

export const loadLbpActivitiesLogSuccess = (activitiesLog) => {
  return {
    type: types.LOAD_LBP_ACTIVITIES_LOG,
    activitiesLog,
  };
};

export const loadLbpCodewordsLogSuccess = (codewordsLog) => {
  return {
    type: types.LOAD_LBP_CODEWORDS_LOG,
    codewordsLog,
  };
};

export const loadLbpCodewordsSuccess = (codewordsObj) => {
  return {
    type: types.LOAD_LBP_CODEWORDS,
    codewordsObj,
  };
};

export const loadLbpBasicsSuccess = (lbpBasicsContent) => {
  return {
    type: types.LOAD_LBP_BASICS,
    lbpBasicsContent: lbpBasicsContent,
  };
};

export const loadLbpLicenceClassesSuccess = (lbpLicenceClasses) => {
  return {
    type: types.LOAD_LBP_LICENCE_CLASSES,
    lbpLicenceClasses,
  };
};

export const postUserProfileSuccess = (userProfileObj) => {
  return {
    type: types.POST_LBP_ACTIVITY_SUCCESS,
    userProfileObj: userProfileObj.LBP,
  };
};

export const postAddActivitySuccess = (params) => {
  return {
    type: types.POST_LBP_ADD_ACTIVITY_SUCCESS,
    params,
  };
};

export const loadLbp = () => async (dispatch) => {
  dispatch(LbpLoading(true));
  await dispatch(loadLbpActivitiesLog());
  await dispatch(loadLbpBasics());
  await dispatch(loadLbpCodewords());
  await dispatch(loadLbpCodewordsLog());
  await dispatch(loadLbpLicenceClasses());
  dispatch(LbpLoading(false));
};

export const loadLbpBasics = () => async (dispatch) => {
  const basics = await GetBasics();
  dispatch(loadLbpBasicsSuccess(basics));
};

export const loadLbpActivitiesLog = () => async (dispatch) => {
  const activities = await GetActivities();
  dispatch(loadLbpActivitiesLogSuccess(activities));
};

export const loadLbpCodewordsLog = () => async (dispatch) => {
  const wordsByUser = await GetCodewordsByUser();
  dispatch(loadLbpCodewordsLogSuccess(wordsByUser));
};

export const loadLbpCodewords = () => async (dispatch) => {
  const words = await GetCodewords();
  dispatch(loadLbpCodewordsSuccess(words));
};

export const loadLbpLicenceClasses = () => async (dispatch) => {
  const classes = await GetLbpLicenceClasses();
  dispatch(loadLbpLicenceClassesSuccess(classes));
};

export const postAddCodewords = (params) => async (dispatch) => {
  await PostLbpUserProfile(params);
  await dispatch(loadUserDetails());
  await dispatch(loadLbpCodewordsLog());
};

export const postLbpDetails = (params) => async (dispatch) => {
  await PostLbpUserProfile(params);
  await dispatch(loadUserDetails());
};

export const postAddActivity = (params) => async (dispatch) => {
  await PostLbpAddActivity(params);
  dispatch(postAddActivitySuccess(params));
  dispatch(loadLbpActivitiesLog());
  dispatch(loadUserDetails());
};
