import React, {useEffect} from 'react';
import Button from '../Form/Button';
import HeadPage from '../HeadPage';
import propTypes from 'prop-types';
import TextBox from '../Form/TextBox';
import { inputChangeHideKeyboardOverlay } from '../../util/mobile';

const TradeClubLink = (props) => {
  const { title, handleTradeClubLink } = props;

  useEffect(() => {
    // Only for Android
    inputChangeHideKeyboardOverlay();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = e.target.querySelector('input').value;

    if (handleTradeClubLink) {
      handleTradeClubLink(data.toUpperCase());
    }
  };

  return (
    <div className="TradeClubLink">
      <div className="TradeClubLink__wrapper-main">
        <HeadPage
          pageTitle={title}
          hasBackButton={true}
          titleBgColor={'white'}
        />
        <div className="TradeClubLink__description">
          <p>Please enter your Trade Club account number. This number has three letters followed by seven digits.</p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <TextBox label="Account Number" value="" />
          <div className="TradeClubLink__wrapper-sub">
            <Button text="Link Account" />
            <div className="TradeClubLink__hr"></div>
            <div className="TradeClubLink__bottom-text">
              <p>Don’t have an account?</p>
              <a href="https://www.itm.tx.co.nz/store" target='_blank' rel="noreferrer">Sign up</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

TradeClubLink.defaultProps = {
  title: null,
  description: null,
  handleTradeClubLink: () => {},
};

TradeClubLink.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  handleTradeClubLink: propTypes.func,
};

export default TradeClubLink;
