import React, { useState } from 'react';
import propTypes from 'prop-types';

const TextBox = (props) => {
  const { label, rows, value } = props;
  const [inputvalue, setValue] =
    value !== null ? useState(value) : useState('');

  return (
    <div className="input-form">
      <div className="input-form__wrapper">
        <div className="floating-label">
          {rows === null ? (
            <input
              className="form-input"
              onChange={(evt) => setValue(evt.target.value)}
              value={inputvalue}
            />
          ) : (
            <textarea
              className="form-input"
              rows={rows}
              onChange={(evt) => setValue(evt.target.value)}
              value={inputvalue}
            />
          )}
          {label ? (
            <label
              className={`${inputvalue.length && 'float'} form-input-label`}
            >
              {label}
            </label>
          ) : null}
        </div>
      </div>
    </div>
  );
};

TextBox.defaultProps = {
  label: null,
  rows: null,
  value: null,
};

TextBox.propTypes = {
  label: propTypes.string,
  rows: propTypes.string,
  value: propTypes.string,
};

export default TextBox;
