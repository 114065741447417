import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import history from '../history';
import { ToastContainer } from 'react-toastify';
import HomeContainer from '../containers/HomeContainer';
import TradeClubContainer from '../containers/TradeClubContainer';
import LbpDiaryContainer from '../containers/LbpDiaryContainer';
import PriorityContainer from '../containers/PriorityContainer';
import DealsContainer from '../containers/DealsContainer';
import AccountsContainer from '../containers/AccountsContainer';
import SignUpContainer from '../containers/SignUpContainer';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import DebugContainer from '../containers/DebugContainer';
import * as Pages from '../pages';
import { store } from '../stores/main';
import { useLoginCallback } from '../hooks/UseLoginCallback';
import ErrorBoundary from './ErrorBoundary';

export default () => {
  useLoginCallback();

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <ToastContainer />
        <Router history={history}>
          <Switch>
            <PrivateRoute
              path="/sign-up"
              exact
              component={SignUpContainer}
              params={{ pageType: 'Complete Signup', hasBackButton: true }}
            />

            <PrivateRoute
              path="/sign-up/customer-type"
              exact
              component={SignUpContainer}
              params={{ pageType: 'Customer Type', hasBackButton: true }}
            />

            <PrivateRoute
              path="/sign-up/choose-store"
              exact
              component={SignUpContainer}
              params={{ pageType: 'Choose ITM Store', hasBackButton: true }}
            />

            <PrivateRoute
              path="/sign-up/closest-store"
              exact
              component={SignUpContainer}
              params={{ pageType: 'Closest ITM Store', hasBackButton: true }}
            />

            <PrivateRoute
              path="/sign-up/search-stores"
              exact
              component={SignUpContainer}
              params={{ pageType: 'Search Stores', hasBackButton: true }}
            />

            <Route
              path="/logout"
              exact
              render={() => <SignUpContainer pageType="Logout" />}
            />

            <Route
              path="/logged-out"
              exact
              render={() => <SignUpContainer pageType="Logged Out" />}
            />

            <Route path="/login" exact component={Pages.SignIn} />

            <Route path="/error/browser" exact component={Pages.TryInBrowser} />

            <Route
              path="/error/login"
              exact
              component={Pages.AuthenticationError}
            />

            <Route
              path="/error/application"
              exact
              component={Pages.ApplicationError}
            />

            <Route
              path="/error/unauthorised"
              exact
              component={Pages.UnauthorisedError}
            />

            <PrivateRoute
              path="/news"
              exact={true}
              component={Pages.NewsPosts}
            />

            <PrivateRoute
              path="/news/categories"
              component={Pages.NewsInterests}
            />

            <PrivateRoute path="/news/article" component={Pages.NewsArticle} />

            {/* If the current URL is /trade-club/, this route is rendered while the rest are ignored */}
            <PrivateRoute
              path="/trade-club"
              exact={true}
              component={TradeClubContainer}
            />

            <PrivateRoute
              path="/document-library"
              exact={true}
              component={Pages.Resources}
            />

            <PrivateRoute
              path="/document-library/categories"
              exact={true}
              component={Pages.DocumentCategories}
            />

            <PrivateRoute
              path="/document-library/categories/category"
              component={Pages.DocumentCategory}
            />

            <PrivateRoute
              path="/document-library/media"
              component={Pages.DocumentMedia}
            />

            <PrivateRoute
              path="/document-library/favourites"
              component={Pages.DocumentFavourites}
            />

            <PrivateRoute
              path="/document-library/recently-viewed"
              component={Pages.DocumentRecentlyViewed}
            />

            {/* If the current URL is /lbp-diary/, this route is rendered while the rest are ignored */}
            <PrivateRoute path="/lbp-diary/" component={LbpDiaryContainer} />

            <PrivateRoute
              path="/priority-card"
              exact={true}
              component={PriorityContainer}
            />

            <PrivateRoute
              path="/priority-card/link-card"
              component={PriorityContainer}
              params={{ pageType: 'login' }}
            />

            <PrivateRoute
              path="/deals"
              exact={true}
              component={DealsContainer}
              params={{ pageType: 'deals' }}
            />

            <PrivateRoute
              path="/deals/article"
              component={DealsContainer}
              params={{ pageType: 'article', hasBackButton: true }}
            />

            <PrivateRoute
              path="/diy"
              exact={true}
              component={DealsContainer}
              params={{ pageType: 'DIY' }}
            />

            <PrivateRoute
              path="/diy/article"
              component={DealsContainer}
              params={{ pageType: 'article', hasBackButton: true }}
            />

            <PrivateRoute
              path="/account"
              exact={true}
              component={AccountsContainer}
              params={{ pageType: 'Account' }}
            />

            <PrivateRoute
              path="/account/details"
              component={AccountsContainer}
              params={{ pageType: 'Account Details' }}
            />

            <PrivateRoute
              path="/account/lbp-details"
              component={AccountsContainer}
              params={{ pageType: 'LBP Details' }}
            />

            <PrivateRoute
              path="/account/notifications"
              component={AccountsContainer}
              params={{ pageType: 'NOTIFICATION SETTINGS' }}
            />

            <PrivateRoute
              path="/account/locate-store"
              component={AccountsContainer}
              params={{ pageType: 'Your local ITM' }}
            />

            <PrivateRoute
              path="/account/local-itm"
              component={AccountsContainer}
              params={{ pageType: 'Local ITM' }}
            />

            <PrivateRoute
              path="/account/priority-card-settings"
              component={AccountsContainer}
              params={{ pageType: 'Priority Card Settings' }}
            />

            <PrivateRoute
              path="/account/priority-card-link"
              component={AccountsContainer}
              params={{ pageType: 'Priority Card Link' }}
            />

            <PrivateRoute
              path="/account/trade-club-settings"
              component={AccountsContainer}
              params={{ pageType: 'Trade club settings' }}
            />

            <PrivateRoute
              path="/account/trade-club-link"
              component={AccountsContainer}
              params={{ pageType: 'Trade club link' }}
            />

            {/* Debug page */}
            <Route path="/debug">
              <DebugContainer />
            </Route>

            {/* If none of the previous routes render anything,
                this route acts as a fallback.
                Important: A route with path="/" will *always* match
                the URL because all URLs begin with a /. So that's
                why we put this one last of all */}
            <PrivateRoute exact path="/" component={HomeContainer} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
};
