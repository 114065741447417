import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Form/Button';
import HeadPage from '../../components/HeadPage';
import TextBoxV2 from '../Form/TextBoxV2';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SelectBoxV2 from '../Form/SelectBoxV2';

const AccountDetails = (props) => {
  const {
    firstName,
    lastName,
    email,
    customerTypes,
    handleAccountDetails,
    customerType,
  } = props;

  const customerTypeOptions = customerTypes.map((element) => ({
    name: element.Title,
    value: element.NodeID,
  }));

  const initialFormValues = {
    firstName,
    lastName,
    email,
    customerType
  };

  const validate = yup.object({
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Please enter your email address'),
  });

  const accountForm = (formikProps) => {
    const { handleChange, values, errors, dirty } = formikProps;

    return (
      <div className="account-details">
        <div className="account-details__wrapper">
          <div className="account-details__heading">
            <HeadPage
              pageTitle="Account Details"
              hasBackButton={true}
              titleBgColor={'white'}
            />
          </div>
          <Form>
            <div className="account-details__input">
              <TextBoxV2
                label="First Name"
                name="firstName"
                readOnly={true}
                value={String(values.firstName)}
              />
              {errors.firstName && (
                <p className="error-message">
                  <i className="fas fa-exclamation"></i>
                  {errors.firstName}
                </p>
              )}

              <TextBoxV2
                label="Last Name"
                name="lastName"
                readOnly={true}
                value={String(values.lastName)}
              />
              {errors.lastName && (
                <p className="error-message">
                  <i className="fas fa-exclamation"></i>
                  {errors.lastName}
                </p>
              )}

              <TextBoxV2
                label="Email Address"
                name="email"
                readOnly={true}
                value={String(values.email)}
              />
              {errors.email && (
                <p className="error-message">
                  <i className="fas fa-exclamation"></i>
                  {errors.email}
                </p>
              )}

              <SelectBoxV2
                onChange={handleChange}
                options={customerTypeOptions}
                value={values.customerType}
                label="Customer type"
                name="customerType"
              />
            </div>
            <div className="account-details__save-btn">
              <Button text="Save Details" isDisabled={!dirty} />
            </div>
          </Form>
        </div>
      </div>
    );
  };

  const handleFormSubmit = (values) => {
    const { customerType } = values;
    const updatedUserDetails = {
      UserSettings: {
        CustomerType: customerType,
      },
    };

    handleAccountDetails(updatedUserDetails);
  };

  return (
    <>
      <div className="create-account">
        <Formik
          initialValues={initialFormValues}
          validationSchema={validate}
          onSubmit={handleFormSubmit}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(formikProps) => accountForm(formikProps)}
        </Formik>
      </div>
    </>
  );
};

AccountDetails.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  customerType: PropTypes.string,
  customerTypes: PropTypes.array,
  handleAccountDetails: PropTypes.func,
};

AccountDetails.defaultProps = {
  customerTypes: [],
  handleAccountDetails: () => {},
};

export default AccountDetails;
