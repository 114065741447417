import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
    const { text, isDisabled, handleSubmit, type, stickyBtn } = props;
    const _type = type !== null ? type : 'Submit';
    const stickyBtnClass =  stickyBtn ? 'form-button--sticky' : '';
    return (
        <div className={`form-button ${stickyBtnClass}`} >
            <button type={_type} className="btn btn--primary" disabled={isDisabled} onClick={handleSubmit}>{text}</button>
        </div>
    );
};

Button.defaultProps = {
    text: null,
    type: null,
    isDisabled: false,
    stickyBtn: false,
    handleSubmit: (()=>{}),
  };
  
Button.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    stickyBtn: PropTypes.bool,
    isDisabled: PropTypes.bool,
    handleSubmit: PropTypes.func,
};
  
export default Button;