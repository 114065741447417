import React from 'react';
import { toast, Slide } from 'react-toastify';

export const ToastNotification = (type = null, heading = null, description = null, closeTime = null, callbackFn = null) => {

  let icon = <i className="fas fa-check"></i>;
  if (type !== 'success') {
    icon = <i className="fas fa-info"></i>;
  }

  return (
    toast(
      <div>
        {heading && <h6> {heading} </h6>}
        {description && <p> {description} </p>}
      </div>
      , {
        position: 'top-center',
        autoClose: closeTime,
        transition: Slide,
        closeOnClick: false,
        icon: () => icon,
        onClose: callbackFn,
        className: type
      })
  );
};
