import React from 'react';
import CtaButton from '../Button/CtaButton';
import PropTypes from 'prop-types';

const TradeCard = (props) => {
  const { headingOne, headingTwo, description, image, linkText, linkUrl, hasArrowIcon } = props;

  return (
    <div className="trade-card">
      <div className="trade-card__wrapper">
        <div className="trade-card__img -bg-image" style={{ backgroundImage: `url(${image})` }}></div>
        <div className="trade-card__content z-index-one">
          <div className="trade-card__headings">
            <h3>
              {headingOne && <span className="yellow-highlight">{headingOne}</span>}
              {headingTwo && <span className="yellow-highlight">{headingTwo}</span>}
            </h3>
          </div>
          <div className="trade-card__description">
            <p>{description}</p>
          </div>
          <CtaButton type="primary" text={linkText} href={linkUrl} arrowIcon={hasArrowIcon} />
        </div>
      </div>
    </div>
  );
};

TradeCard.defaultProps = {
  headingOne: null,
  headingTwo: null,
  description: null,
  image: null,
  linkText: null,
  linkUrl: null,
  hasArrowIcon: false
};

TradeCard.propTypes = {
  headingOne: PropTypes.string,
  headingTwo: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  hasArrowIcon: PropTypes.bool
};

export default TradeCard;
