import * as types from './ActionTypes';
import { GetUserDetails } from '../api/GetUserDetails';
import { GetCustomerType } from '../api/GetCustomerType';
import { PostStoreDetails } from '../api/PostStoreDetails';
import { PostUserDetails } from '../api/PostUserDetails';
import { GetStoreDetailsByLocation } from '../api/GetStoreDetailsByLocation';
import { GetLbpLicenceClasses } from '../api/GetLbp';
import { ToastNotification } from '../components/ToastNotification';
import {loadStores} from './StoresActions';
import history from '../history';

export const loadUserDetailsStart = (loading) => {
    return {
        type: types.LOAD_ACCOUNT_DETAILS_START,
        loading
    };
};

export const loadUserDetailsSuccess = (accountDetails) => {
    return {
        type: types.LOAD_ACCOUNT_DETAILS_COMPLETE,
        accountDetails
    };
};

export const loadStoresByLocationSuccess = (storeDetailsByLocation) => {
    return {
        type: types.LOAD_STORE_DETAILS_BY_LOCATION,
        storeDetailsByLocation
    };
};

export const loadUserDetails = () => async (dispatch, getState) => {
    dispatch(loadUserDetailsStart(true));

    const account = {

    };

    account.profile = await GetUserDetails();
    await dispatch(loadStores());

    if (getState().account.customerTypes.length <= 0) {
        account.customerTypes = (await GetCustomerType()).Items;
    }

    if (getState().account.lbpLicenceClasses.length <= 0) {
        account.lbpLicenceClasses = await GetLbpLicenceClasses();
    }

    dispatch(loadUserDetailsSuccess(account));
};

export const updateStoreDetails = (storeID) => async (dispatch) => {
    await PostStoreDetails(storeID);
    const profile = await GetUserDetails();

    dispatch(loadUserDetailsSuccess({
        profile,
    }));    
};

export const updateUserDetails = (userData, pageType) => async (dispatch) => {
    await PostUserDetails(userData);
    const profile = await GetUserDetails();

    dispatch(loadUserDetailsSuccess({
        profile,
    }));   
    
    const goBack = () => {
        if (pageType==='account details') {
            history.goBack();
        }
    };

    ToastNotification('success', 'Got it!', 'Your account details have been updated', 3000, goBack); 
};

export const loadStoresByLocation = (latitude, longitude) => async (dispatch) => {
    const stores = await GetStoreDetailsByLocation(latitude, longitude);

    dispatch(loadStoresByLocationSuccess(stores));
};
