import React, { PureComponent } from 'react';
import PriorityCard from '../components/Card/PriorityCard';
import PriorityCardLogin from '../components/PriorityCard/PriorityCardLogin';
import PropTypes from 'prop-types';
import { getCookie, setCookie } from '../util/cookie';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/PriorityActions';
import { pageViewGA } from '../services/GoogleAnalytics';
import Loader from './../components/Loader';
import PriorityCardDigital from '../components/PriorityCard/PriorityCardDigital';
import StickyNavigation from '../components/StickyNavigation';

class PriorityContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPriorityCardClosed: false,
    };
  }

  componentDidMount() {
    const { pageType } = this.props;

    if (getCookie('hidePriorityCard') === 'true') {
      this.setState({ isPriorityCardClosed: true });
    }

    switch (pageType) {
      case 'login':
        pageViewGA('Priority Link Card');
        break;
      default:
        pageViewGA('Priority Card');
        break;
    }
  }

  handleButtonClose() {
    setCookie('hidePriorityCard', 'true', '315360000');
    this.setState({ isPriorityCardClosed: true });
  }

  renderPriorityCardLogin() {
    const { actions } = this.props;

    const handleAddCardClick = (cardNumber) => {
      if (cardNumber) {
        actions.updatePriorityCardNumberByUser(cardNumber);
      }
    };

    return (
      <PriorityCardLogin
        title="link a Priority Card"
        handleAddCard={handleAddCardClick}
      />
    );
  }

  renderPriorityHome() {
    const { loading, profile, user } = this.props;
    const priorityCardNumber = profile.PriorityCardNumber;
    const firstName = user.firstName;
    const lastName = user.familyName;

    if (loading) {
      return <Loader />;
    } else if (priorityCardNumber !== 0 && priorityCardNumber !== null) {
      return (
        <PriorityCardDigital
          headingMain="your digital"
          headingSub="priority card"
          description="Scan at the checkout"
          PriorityCardNumber={priorityCardNumber}
          firstName={firstName}
          lastName={lastName}
        />
      );
    } else if (!this.state.isPriorityCardClosed) {
      return (
        <PriorityCard
          headingMain="have an itm"
          headingSub="priority card?"
          description="Link your Priority Card, or sign up for exclusive in-store deals."
          handleCloseClick={this.handleButtonClose}
        />
      );
    }
  }

  render() {
    const { pageType } = this.props;
    return (
      <>
        {
          /* initial page of Priority page */
          pageType === null && this.renderPriorityHome()
        }
        {
          /* priority login page */
          pageType === 'login' && this.renderPriorityCardLogin()
        }
        <StickyNavigation activeTabName="home" />
      </>
    );
  }
}

PriorityContainer.propTypes = {
  profile: PropTypes.object,
  user: PropTypes.object,
  pageType: PropTypes.string,
  loading: PropTypes.bool,
  actions: PropTypes.object,
};

PriorityContainer.defaultProps = {
  profile: {},
  user: {},
  pageType: null,
};

const mapStateToProps = (state) => {
  const {
    account: { profile, loading },
    auth: { user },
  } = state;

  return {
    profile,
    user,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorityContainer);
