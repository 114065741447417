import React from 'react';
import { Route } from 'react-router-dom';
import propTypes from 'prop-types';
import Loader from '../Loader';
import {useLogin} from '../../hooks/UseLogin';
import SignUpContainer from '../../containers/SignUpContainer';

const PrivateRoute = (props) => {
  const { component: Component, path, exact, params } = props;
  
  const pageType =
    params && params['pageType']
      ? params['pageType']
      : null;

  const hasBackButton =
    params && params['hasBackButton']
      ? params['hasBackButton']
      : null;

  const {isLoggedIn, signUpStage} = useLogin();

  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        if (signUpStage === 'unknown') {
          return (
            <Loader/>
          );
        }

        if (isLoggedIn && signUpStage === 'start') {
          return (
            <SignUpContainer pageType="Complete Signup" hasBackButton={false} />
          );
        }        

        if (isLoggedIn) {
          return (
            <Component pageType={pageType} hasBackButton={hasBackButton} />
          );
        }        
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: propTypes.elementType,
  path: propTypes.string,
  exact: propTypes.bool,
  params: propTypes.object,
};

PrivateRoute.defaultProps = {
  component: null,
  path: null,
  exact: false,
  params: null,
};

export default PrivateRoute;
