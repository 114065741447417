import React from 'react';
import PropTypes from 'prop-types';
import { VictoryPie, VictoryAnimation, VictoryLabel } from 'victory';

class ProgressBarCircular extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      percent: 0,
      data: this.getData(0),
    };
  }

  componentDidMount() {
    const { percentage } = this.props;
    let percent = percentage;

    this.setStateInterval = window.setInterval(() => {
      this.setState({
        percent,
        data: this.getData(percent),
      });
    }, 2000);
  }

  componentWillUnmount() {
    window.clearInterval(this.setStateInterval);
  }

  getData(percent) {
    return [
      { x: 1, y: percent },
      { x: 2, y: 100 - percent },
    ];
  }

  render() {
    return (
      <div>
        <svg viewBox="0 0 400 400" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate={{ duration: 1000 }}
            width={400}
            height={400}
            data={this.state.data}
            innerRadius={120}
            cornerRadius={25}
            labels={() => null}
            style={{
              data: {
                fill: ({ datum }) => {
                  let color = '#FFF200';
                  if (datum.y > 45) {
                    color = '#FFA500';
                  } else if (datum.y > 75) {
                    color = '#FF4F00';
                  }
                  return datum.x === 1 ? color : '#FFFFFF1A';
                },
              },
            }}
          />
          <VictoryAnimation duration={1000} data={this.state}>
            {(newProps) => {
              return (
                <VictoryLabel
                  textAnchor="middle"
                  verticalAnchor="middle"
                  x={200}
                  y={200}
                  text={`${Math.round(newProps.percent)}%`}
                  style={{
                    fontSize: 84,
                    fill: '#FFFFFF',
                    fontWeight: 700,
                    lineHeight: 84,
                  }}
                />
              );
            }}
          </VictoryAnimation>
        </svg>
      </div>
    );
  }
}
ProgressBarCircular.defaultProps = {
  percentage: null
};

ProgressBarCircular.propTypes = {
  percentage: PropTypes.number
};

export default ProgressBarCircular;
