import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import {B2CPolicies} from '../Config';

export function useLoginCallback() {
    const { instance } = useMsal();

    useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
          event.payload.account
        ) {
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */          
          if (
            event.payload.idTokenClaims['tfp'] === B2CPolicies.names.editProfile
          ) {
            const originalSignInAccount = instance
              .getAllAccounts()
              .find(
                (account) =>
                  account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                  account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                  account.idTokenClaims['tfp'] === B2CPolicies.names.signUpSignIn
              );
  
            const request = {
              authority: B2CPolicies.authorities.signUpSignIn.authority,
              account: originalSignInAccount,
            };
  
            // silently login again with the signUpSignIn policy
            instance.ssoSilent(request);
          }
  
          /**
           * Below we are checking if the user is returning from the reset password flow.
           * If so, we will ask the user to reauthenticate with their new password.
           * If you do not want this behavior and prefer your users to stay signed in instead,
           * you can replace the code below with the same pattern used for handling the return from
           * profile edit flow
           */
          if (
            event.payload.idTokenClaims['tfp'] ===
            B2CPolicies.names.forgotPassword
          ) {
            const request = {
              authority: B2CPolicies.authorities.signUpSignIn.authority,
            };
            instance.loginRedirect(request);
          }
        }
  
        if (event.eventType === EventType.LOGIN_FAILURE) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
            const request = {
              authority: B2CPolicies.authorities.forgotPassword.authority,
              scopes: [],
            };
            instance.loginRedirect(request);
          }
        }
      });
  
      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    }, [instance]);    
}