import React from 'react';
import PropTypes from 'prop-types';
import * as ErrorPages from '../pages/error';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/SystemActions';
import history from '../history';

/**
 * The error boundary covers two error case handling:
 * 1) Unexpected application errors (probably code crashes)
 * 2) Application handled errors such as Unauthorised access or session expiry (these are pulled from redux state)
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, exception: { ...error } };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error handler', {...error, errorInfo });
  }

  render() {
    const {hasError, error, actions, exception} = this.props;

    if (this.state.hasError) {
      /**
       * Render generic error page. Probably bad code somewhere...
       */
      return <ErrorPages.ApplicationError error={exception}/>;
    } else if (hasError) {
      // Clear the error so any actions on the handlers will not end up back here.
      actions.systemErrorClear();

      // Replace history so we cannot navigate back to the error page
      history.replace(error.page);

      // Conditionally render the correct handler
      switch (error.type) {
        case 'Unauthorised':
          return <ErrorPages.UnauthorisedError error={error}/>;    

        case 'Login':
          return <ErrorPages.AuthenticationError error={error}/>;
      }

      return <ErrorPages.ApplicationError error={error}/>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.array,
  error: PropTypes.object,
  exception: PropTypes.object,
  hasError: PropTypes.bool,
  actions: PropTypes.object
};

ErrorBoundary.defaultProps = {
  hasError: false
};

const mapStateToProps = (state) => {
  const {
    system: {error, hasError}
  } = state;

  return { error, hasError };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);