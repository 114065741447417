import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import SimplePageLayout from '../../layouts/SimplePageLayout';
import Button from '../../components/Form/Button';

export const AuthenticationError = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      onRedirectNavigate: () => false,
    });
  }, []);

  const onButtonClick = () => {
    // Invoke a full page refresh
    location.href = '/';
  };

  return (
    <SimplePageLayout
      pageTitle="Technical Error"
      pageDescription="Something went wrong..."
      hasBackButton={false}
    >
      <div className="SystemError__description">
        <p>Looks like we had a problem logging you in...</p>
      </div>

      <div>
        <Button
          text="Sign in again"
          type="primary"
          handleSubmit={onButtonClick}
        />
      </div>
    </SimplePageLayout>
  );
};

AuthenticationError.propTypes = {};

AuthenticationError.defaultProps = {};
