import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import JsBarcode from 'jsbarcode';
import PriorityCardImage from '../../components/svg/PriorityCardImage';
import PriorityCardSmallImage from '../../components/svg/PriorityCardSmallImage';

const PriorityCardDigital = (props) => {
  const [isPop, setIsPop] = useState(false);
  const {
    headingMain,
    headingSub,
    description,
    firstName,
    lastName,
    PriorityCardNumber,
  } = props;

  useEffect(() => {
    JsBarcode('.barcode').options({ margin: 0, height: 140, width: 3 }).init();
  }, [isPop]);

  const handleClose = () => {
    setIsPop(false);
  };

  const handleModal = (event) => {
    event.preventDefault();
    setIsPop(true);
  };
  
  return (
    <div className="priority-card-digital">
      <a href='/' onClick={handleModal}>
        <div className="priority-card-digital__wrapper">
          <div className="priority-card-digital__content">
            <div className="priority-card-digital__heading">
              <h2 className="priority-card-digital__heading__text">
                <span>{headingMain}</span>
                <br />
                <span>{headingSub}</span>
              </h2>
            </div>
            <div className="priority-card-digital__description">
              <p className="priority-card-digital__description__text">
                {description}
              </p>
            </div>
          </div>
          <div className="priority-card-digital__image">
            <PriorityCardSmallImage />
          </div>
        </div>
      </a>

      <Modal handleModalVisibility={isPop} handleClose={handleClose}>
        <div className="modal__wrapper">
          <div className="modal__content">
            <div className="modal__items">
              <div className="modal__card-img">
                <PriorityCardImage />
              </div>
              <div className="modal__card-number">
                <h6> card number</h6>
                <h2>{PriorityCardNumber}</h2>
              </div>
              <div className="modal__username">
                <h2>{firstName}</h2>
                <h2>{lastName}</h2>
              </div>
            </div>
          </div>
          <h3 className="modal__text">
            <span> SCAN YOUR DIGITAL</span> <br />
            <span> PRIORITY CARD AT YOUR </span> <br />
            <span> LOCAL ITM</span>
          </h3>
          
          <svg
            className="barcode"
            // eslint-disable-next-line react/no-unknown-property
            jsbarcode-format="CODE128"
            // eslint-disable-next-line react/no-unknown-property
            jsbarcode-value={PriorityCardNumber}
            // eslint-disable-next-line react/no-unknown-property
            jsbarcode-textmargin="0"
            // eslint-disable-next-line react/no-unknown-property
            jsbarcode-fontoptions="bold"
          />
        </div>
      </Modal>
    </div>
  );
};

PriorityCardDigital.defaultProps = {
  headingMain: null,
  headingSub: null,
  description: null,
  PriorityCardNumber: null,
  firstName: null,
  lastName: null,
};

PriorityCardDigital.propTypes = {
  headingMain: PropTypes.string,
  headingSub: PropTypes.string,
  description: PropTypes.string,
  PriorityCardNumber: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
export default PriorityCardDigital;
