import * as types from './ActionTypes';
import history from '../history';
import { GetNewsPosts, GetNewsInterests } from '../api/GetNews';
import { loadUserDetails } from './AccountsActions';
import { PostNewsInterests } from '../api/PostNews';
import { ToastNotification } from '../components/ToastNotification';

export const loadNewsPostsByUserStart = (loading) => {
  return {
    type: types.LOAD_NEWS_POSTS_BY_USERS_START,
    loading,
  };
};

export const loadNewsPostsByUserSuccess = (newsPostsByUser) => {
  return {
    type: types.LOAD_NEWS_POSTS_BY_USERS_COMPLETE,
    newsPostsByUser,
  };
};

export const loadNewsInterestsByUserStart = (loading) => {
  return {
    type: types.LOAD_NEWS_INTERESTS_BY_USER_START,
    loading,
  };
};

export const loadNewsInterestsByUserSuccess = (newsInterestsByUser) => {
  return {
    type: types.LOAD_NEWS_INTERESTS_BY_USER_COMPLETE,
    newsInterestsByUser,
  };
};

export const postNewsInterestsByUserStart = (interestsSaveBtnState) => {
  return {
    type: types.POST_NEWS_INTERESTS_BY_USERS_START,
    interestsSaveBtnState,
  };
};

export const postNewsInterestsByUserSuccess = (interestsSaveBtnState) => {
  return {
    type: types.POST_NEWS_INTERESTS_BY_USERS_COMPLETE,
    interestsSaveBtnState,
  };
};

export const postNewsInterestsByUser =
  (selectedInterests) => async (dispatch) => {
    dispatch(postNewsInterestsByUserStart(true));

    const goBack = () => {
      dispatch(postNewsInterestsByUserSuccess(false));
      history.goBack();
    };

    try {
      await PostNewsInterests(selectedInterests);
      dispatch(loadUserDetails());

      ToastNotification(
        'success',
        'Got it!',
        'We\'ll send you more of what you\'re into',
        3000,
        goBack,
      );
    } catch (ex) {
      ToastNotification(
        'error',
        'Something went wrong',
        'Please check your internet and try again.',
        3000,
        goBack,
      );
    }
  };

export const loadNewsInterestsByUser = () => async (dispatch, getState) => {
  dispatch(loadNewsInterestsByUserStart(true));

  const {
    account: { profile },
  } = getState();

  const selectedNewsInterests = await GetNewsInterests();
  const newsInterestsByUser = profile.News?.NewsCategories.split(',').map((n) =>
    Number(n),
  );
  newsInterestsByUser.forEach((id) => {
    const found = selectedNewsInterests.Items.find(
      (node) => node.NodeID === id,
    );
    if (found) {
      found.IsChecked = true;
    }
  });

  dispatch(loadNewsInterestsByUserSuccess({ selectedNewsInterests }));
};

export const loadNewsPostsByUser = () => async (dispatch, getState) => {
  dispatch(loadNewsPostsByUserStart(true));

  const {
    account: { profile },
  } = getState();

  const news = await GetNewsPosts(profile.News.NewsCategories, profile.Store.NodeID);

  dispatch(loadNewsPostsByUserSuccess(news));
};
