import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import {Environment} from '../Config';

let enhancer;
const composeEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middleware = applyMiddleware(thunk);

if (window.__REDUX_DEVTOOLS_EXTENSION__ && Environment !== 'production') {
  enhancer = compose(middleware, composeEnhancer);
} else {
  enhancer = compose(middleware);
}

export const store = createStore(rootReducer, enhancer);