import React from 'react';
import PropTypes from 'prop-types';

const ArticleInfo = (props) => {

  const { publishedDate, author } = props;

  return (
    <div className="article-page__info">
      <p className="small-text">{publishedDate} <span> By </span> {author}</p>
    </div>
  );
};

ArticleInfo.defaultProps = {
  publishedDate: null,
  author: null
};

ArticleInfo.propTypes = {
  publishedDate: PropTypes.string,
  author: PropTypes.string
};

export default ArticleInfo;