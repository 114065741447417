import React from 'react';
import SimplePageLayout from '../../layouts/SimplePageLayout';

export const TryInBrowser = () => {

    return (
      <SimplePageLayout
        pageTitle="The ITM App"
        hasBackButton={false}
      >
      <p className="SystemError__description">You&apos;ve been using the application in the browser. <a href="/" target="_blank" rel="noreferrer">Click here to open again</a></p>

      </SimplePageLayout>
    );
};

TryInBrowser.propTypes = {};
TryInBrowser.defaultProps = {};
