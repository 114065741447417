import React from 'react';
import PropTypes from 'prop-types';
import TextBoxV2 from '../Form/TextBoxV2';
import SelectBoxV2 from '../Form/SelectBoxV2';
import DatePickerv2 from '../Form/DatePickerV2';
import Button from '../Form/Button';

import { Formik, Form } from 'formik';
import * as yup from 'yup';


const LbpProfileSetupForm = (props) => {
  const { onSubmit, lbpNumber, expiryDate, licenceClassOptions, licenceClass, minDate } = props;

  // By deafult if user has not seleteced licenceClass, then the value will be 0 so we need to select first option
  let selectedLicenceClass = licenceClass;
  if (selectedLicenceClass === 0) {
    selectedLicenceClass = licenceClassOptions[0].value;
  }

  const initialFormValues = {
    lbpNumber,
    expiryDate,
    licenceClass : selectedLicenceClass.toString()
  };

  // yup validtion schema, to set rules for validation
  const validate = yup.object({
    lbpNumber: yup.string().required('Please enter your Lbp number')
  });

  const handleFormSubmit = (values) => {
    const lbpDetails = {
      LBPNumber: values.lbpNumber,
      LicenceClass: Number(values.licenceClass),
      LicenceExpiryDate: values.expiryDate,
    };
    onSubmit(lbpDetails);
  };

  const profileSetupForm = (formikProps) => {
    const { values, errors, dirty } = formikProps;
    
    return (
      <Form>
        <TextBoxV2 label="LBP Number" name="lbpNumber" value={values.lbpNumber} />
        {errors.lbpNumber && (
          <p className="error-message">
            <i className="fas fa-exclamation"></i>
            {errors.lbpNumber}
          </p>
        )}
        <SelectBoxV2
          options={licenceClassOptions}
          value={values.licenceClass}
          label="Licence Class"
          name="licenceClass"
        />
        <DatePickerv2 label="Licence Expiry" value={values.expiryDate} min={minDate} name="expiryDate" />
        {/* Disable the button only when the user is trying to edit the saved values  */}
        <Button text="Save Details" isDisabled={!dirty && lbpNumber !== ''}/>
      </Form>
    );
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validate}
      onSubmit={handleFormSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => profileSetupForm(formikProps)}
    </Formik>
  );
};

LbpProfileSetupForm.propTypes = {
  onSubmit: PropTypes.func,
  lbpNumber: PropTypes.string,
  minDate: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceClass: PropTypes.number,
  licenceClassOptions: PropTypes.array
};

LbpProfileSetupForm.defaultProps = {
  lbpNumber: '',
  minDate: '',
  expiryDate: '',
  licenceClass: 0,
  licenceClassOptions: [],
  onSubmit: () => { },
};

export default LbpProfileSetupForm;