import React, { useState } from 'react';
import Button from '../Form/Button';
import HeadPage from '../HeadPage';
import propTypes from 'prop-types';
import TextBox from '../Form/TextBox';
import { ToastNotification } from '../ToastNotification';

const PriorityCardLogin = (props) => {
  const { title, handleAddCard } = props;
  const [inputValue, setValue] = useState(' ');

  const handleSubmit = (e) => {
    e.preventDefault();

    const cardNumber = e.target.querySelector('input').value;
    setValue(cardNumber);

    const priorityCardRegex = /^[9]\d{7}$/;
    if (
      cardNumber &&
      cardNumber != null &&
      cardNumber.match(priorityCardRegex)
    ) {
      return handleAddCard(cardNumber);
    } else {
      ToastNotification(
        'error',
        'Invalid Card Number',
        'Check the number is entered correctly or contact the ITM support office',
        1500,
      );
    }    
  };

  return (
    <div className="PriorityCardLogin">
      <div className="PriorityCardLogin__wrapper-main">
        <HeadPage
          pageTitle={title}
          hasBackButton={true}
          titleBgColor={'white'}
        />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="PriorityCardLink__description">
            <p>Please enter your priority card number. This number starts with 9 and is followed by seven digits.</p>
          </div>
                    
          <TextBox label="Card Number" />
          {!inputValue ? (
            <span className="error-message">Enter PriorityCard Number</span>
          ) : null}
          <div className="PriorityCardLogin__wrapper-sub">
            <Button text="Add Card" />
            <div className="PriorityCardLogin__hr"></div>
            <div className="PriorityCardLogin__bottom-text">
              <p>Don’t have an Priority Card?</p>
              <a
                href="https://www.itm.tx.co.nz/store"
                target="_blank"
                rel="noreferrer"
              >
                Sign up
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

PriorityCardLogin.defaultProps = {
  title: null,
  handleAddCard: ()=>{},
};

PriorityCardLogin.propTypes = {
  title: propTypes.string,
  handleAddCard: propTypes.func,
};

export default PriorityCardLogin;
