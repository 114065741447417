import {
  getAuthenticatedAxios,
  withErrorHandler,
} from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const PostFavourites = async (favouritesIds) => {
  const payload = {
    DocumentLibrary: { MediaFavourites: favouritesIds },
  };

  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      payload,
    ),
  );

  return data;
};

export const PostRecentlyViewed = async (recentlyViewdIds) => {
  const payload = {
    DocumentLibrary: { MediaRecentlyViewed: recentlyViewdIds },
  };

  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      payload,
    ),
  );

  return data;
};
