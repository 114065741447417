import React from 'react';
import propTypes from 'prop-types';
import {useField} from 'formik';

const DatePickerV2 = (props) => {
  const { label, min, max } = props;
  const [field] = useField(props);
  const { value, onChange, name } = field;

  return (
    <div className="input-form">
      <div className="input-form__wrapper">
        <div className="input-form__field">
          <input
            className="form-input"
            onChange={onChange}
            value={value}
            type="date"
            min={min}
            max={max}
            name={name}
          />
          <label className={'float form-input-label'}>{label}</label>
        </div>
        <i className="far fa-calendar-alt"></i>
      </div>
    </div>
  );
};

DatePickerV2.defaultProps = {
  label: null,
  max: null,
  min: null,
  value: new Date()
};

DatePickerV2.propTypes = {
  label: propTypes.string,
  max: propTypes.string,
  min: propTypes.string,
  value: propTypes.string
};
export default DatePickerV2;
