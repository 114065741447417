import React from 'react';
import PropTypes from 'prop-types';
import HeadPage from '../../components/HeadPage';
import Button from '../../components/Form/Button';

import TextBoxV2 from '../Form/TextBoxV2';
import SelectBoxV2 from '../Form/SelectBoxV2';
import DatePickerv2 from '../Form/DatePickerV2';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

const LBPDetails = (props) => {
  const { LBPnumber, LicenceExpiry, lbpLicenceClasses, selectedLicense, minDate, handleLbpDetailsChange } = props;

  // By deafult if user has not seleteced licenceClass, then the value will be 0 so we need to select first option
  let selectedLicenceClass = selectedLicense;
  if (selectedLicenceClass === 0) {
    selectedLicenceClass = lbpLicenceClasses[0].value;
  }

  const initialFormValues = {
    LBPnumber,
    expiryDate: LicenceExpiry,
    licenceClass: selectedLicenceClass.toString()
  };

  // yup validtion schema, to set rules for validation
  const validate = yup.object({
    LBPnumber: yup.string().required('Please enter your Lbp number')
  });

  const lbpForm = (formikProps) => {
    const { values, errors, dirty } = formikProps;

    return (
      <div className="lbp-details__input">
        <Form>
          <TextBoxV2 label="LBP Number" name="LBPnumber" value={values.LBPnumber} />
          {errors.LBPnumber && (
            <p className="error-message">
              <i className="fas fa-exclamation"></i>
              {errors.LBPnumber}
            </p>
          )}
          <SelectBoxV2
            options={lbpLicenceClasses}
            value={values.licenceClass}
            label="License Class"
            name="licenceClass"
          />
          <DatePickerv2 label="License Expiry" value={values.expiryDate} min={minDate} name="expiryDate" />
          {/* Disable the button only when the user is trying to edit the saved values  */}
          <Button text="Save Details" isDisabled={!dirty && LBPnumber !== ''} />
        </Form>
      </div>
    );
  };

  const handleFormSubmit = (values) => {
    let lbpDetails = {
      LBPNumber: values.LBPnumber,
      LicenceClass: Number(values.licenceClass),
      LicenceExpiryDate: values.expiryDate,
    };
    handleLbpDetailsChange(lbpDetails);
  };

  return (
    <div className="lbp-details">
      <div className="lbp-details__wrapper">
        <div className="lbp-details__heading">
          <HeadPage
            pageTitle="LBP details"
            pageDescription="Record learning activities and stay on top of your LBP license requirements."
            hasBackButton={true}
            titleBgColor={'white'}
          />
        </div>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validate}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => lbpForm(formikProps)}
        </Formik>
      </div>
    </div>
  );
};

LBPDetails.propTypes = {
  LBPnumber: PropTypes.string,
  LicenceExpiry: PropTypes.string,
  selectedLicense: PropTypes.number,
  title: PropTypes.string,
  lbpLicenceClasses: PropTypes.array,
  minDate: PropTypes.string,
  handleLbpDetailsChange: PropTypes.func
};

LBPDetails.defaultProps = {
  LBPnumber: '',
  LicenceExpiry: '',
  title: '',
  lbpLicenceClasses: null,
  minDate: '',
  handleLbpDetailsChange: () => { }
};

export default LBPDetails;
