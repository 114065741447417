import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DocumentLibraryCard = (props) => {
  const {
    title,
    figurePath,
    isFullWidth,
    linkUrl,
    iconType,
    isFavourite,
    handleSelectFavourite,
    handleRecentlyViewed,
    id,
  } = props;

  let icon = null;

  switch (iconType) {
    case 'pdf':
      icon = <i className="fas fa-file-pdf"></i>;
      break;
    case 'image':
      icon = <i className="fas fa-file-pdf"></i>;
      break;
    case 'video':
      icon = <i className="fas fa-file-video"></i>;
      break;
    default:
      if (figurePath) {
        icon = <img src={figurePath} />;
      }
      break;
  }

  const isExternal = linkUrl.startsWith('http');

  const handleFavouriteClick = () => {
    if (handleSelectFavourite) {
      handleSelectFavourite(id);
    }
  };

  const handleRecentlyViewedClick = () => {
    if (handleRecentlyViewed) {
      handleRecentlyViewed(id, linkUrl);
    }
  };

  const favouriteIcon = isFavourite ? (
    <i className="fas fa-star document-library-card__favourite__star--active"></i>
  ) : (
    <i className="far fa-star document-library-card__favourite__star"></i>
  );

  const cornerElement = handleSelectFavourite ? (
    <button
      className="document-library-card__favourite"
      onClick={handleFavouriteClick}
    >
      <div className="document-library-card__favourite__backdrop"></div>
      {favouriteIcon}
    </button>
  ) : (
    <div className="document-library-card__favourite">
      <div className="document-library-card__favourite__backdrop document-library-card__favourite__backdrop--small"></div>
    </div>
  );

  const body = isExternal ? (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className="document-library-card__wrapper"
      onClick={handleRecentlyViewedClick}
    >
      {icon && <div className="document-library-card__figure">{icon}</div>}
      {title && <h4 className="document-library-card__title">{title}</h4>}
    </a>
  ) : (
    <Link
      to={linkUrl}
      className="document-library-card__wrapper"
      onClick={handleRecentlyViewedClick}
    >
      {icon && <div className="document-library-card__figure">{icon}</div>}
      {title && <h4 className="document-library-card__title">{title}</h4>}
    </Link>
  );

  return (
    <div
      className={`document-library-card ${
        isFullWidth ? 'document-library-card--full-width' : ''
      }`}
    >
      {body}
      {cornerElement}
    </div>
  );
};

DocumentLibraryCard.defaultProps = {
  id: null,
  title: ' ',
  figurePath: null,
  isFullWidth: false,
  linkUrl: null,
  iconType: null,
  isFavourite: false,
  handleSelectFavourite: null,
  handleRecentlyViewed: null,
};
DocumentLibraryCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  figurePath: PropTypes.string,
  isFullWidth: PropTypes.bool,
  linkUrl: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  isFavourite: PropTypes.bool,
  handleSelectFavourite: PropTypes.func,
  handleRecentlyViewed: PropTypes.func,
};

export default DocumentLibraryCard;
