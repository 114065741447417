import React from 'react';
import PropTypes from 'prop-types';

const TradeClubCard = (props) => {

  const { totalPoints, EarnedPointsForThisMonth, ExpiringPointsForThisMonth, LastUpdatedOn } = props;

  return (
    <div className="trade-club-card">
      <div className="trade-club-card__wrapper">
        <h3 className="black-highlight">Trade Club points</h3>
        <div className="trade-club-card__total-points">
          <p>Total points</p>
          <span>{totalPoints}</span>
        </div>
        <div className="trade-club-card__monthly-points">
          <div className="trade-club-card__monthly-points__earned">
            <span> + {EarnedPointsForThisMonth} points earned</span>
            <span>this month.</span>
          </div>
          <div className="trade-club-card__monthly-points__expiriny">
            <span> - {ExpiringPointsForThisMonth} points expiring</span>
            <span>this month.</span>
          </div>
        </div>
        <div className="trade-club-card__last-updated">
          <p>{LastUpdatedOn}</p>
        </div>
      </div>
    </div>
  );
};

TradeClubCard.defaultProps = {
  totalPoints: null,
  EarnedPointsForThisMonth: 0,
  ExpiringPointsForThisMonth: 0,
  LastUpdatedOn: null
};

TradeClubCard.propTypes = {
  totalPoints: PropTypes.number,
  EarnedPointsForThisMonth: PropTypes.number,
  ExpiringPointsForThisMonth: PropTypes.number,
  LastUpdatedOn: PropTypes.string
};

export default TradeClubCard;