import { LogLevel } from '@azure/msal-browser';

/**
 * Variables from environment at build time
 */
export const BasePath = process.env.REACT_APP_BASE_PATH || '';
export const BaseUrl = process.env.REACT_APP_API_GATEWAY_URL || '';
export const GoogleApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const Environment = process.env.REACT_APP_ENVIRONMENT || 'test';

/**
 * Build a request URL for a given relative path
 * 
 * @param {string} path 
 * @returns 
 */
export function buildRequestUrl(path) {
    return `${BaseUrl}${BasePath}${path}`;
}

/**
 * Microsoft B2C Policies
 */
const B2CDomain = process.env.REACT_APP_B2C_DOMAIN;
const B2CBasePath = process.env.REACT_APP_B2C_BASE_PATH;

const B2CNames = {
    signUpSignIn: process.env.REACT_APP_B2C_SIGNIN_PROFILE,
    forgotPassword: process.env.REACT_APP_B2C_FORGOT_PROFILE,
    editProfile: process.env.REACT_APP_B2C_EDIT_PROFILE
};

export const B2CPolicies = {
    names: B2CNames,
    authorities: {
        signUpSignIn: {
            authority: `https://${B2CDomain}${B2CBasePath}/${B2CNames.signUpSignIn}`
        },
        forgotPassword: {
            authority: `https://${B2CDomain}${B2CBasePath}/${B2CNames.forgotPassword}`
        },
        editProfile: {
            authority: `https://${B2CDomain}${B2CBasePath}/${B2CNames.editProfile}`
        },
    },
    authorityDomain: B2CDomain,
};

/**
 * Microsoft B2C Config
 */
export const MsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID, 
        authority: B2CPolicies.authorities.signUpSignIn.authority, 
        knownAuthorities: [B2CPolicies.authorityDomain],
        redirectUri: '/login',
        postLogoutRedirectUri: '/logged-out', 
        navigateToLoginRequestUrl: false, 
    },
    telemetry: {
        application: {
            appName: 'ITM Application'
        }
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const B2CScopes = ['openid'];