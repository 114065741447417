import { combineReducers } from 'redux';
import HomeReducer from './HomeReducer';
import DocumentsLibraryReducer from './DocumentsLibraryReducer';
import NewsReducer from './NewsReducer';
import TradeClubReducer from './TradeClubReducer';
import PriorityCardReducer from './PriorityCardReducer';
import LbpReducer from './LbpReducer';
import DealsReducer from './DealsReducer';
import AccountsReducer from './AccountsReducer';
import SignupReducer from './SignupReducer';
import StoresReducer from './StoresReducer';
import AuthReducer from './AuthReducer';
import SystemReducer from './SystemReducer';

const rootReducer = combineReducers({
  stores: StoresReducer,
  auth: AuthReducer,
  signUp: SignupReducer,
  home: HomeReducer,
  documents: DocumentsLibraryReducer,
  priorityCard: PriorityCardReducer,
  news: NewsReducer,
  lbp: LbpReducer,
  tradeClub: TradeClubReducer,
  deals: DealsReducer,
  account: AccountsReducer,
  system: SystemReducer
});

export default rootReducer;
