export function inputChangeHideKeyboardOverlay() {
    if(getMobileOperatingSystem() === 'Android') {
      const $inputElements = document.querySelectorAll('input');
      const handleAndroidKeyboardToggle = (state) => {
        if(state === 'open') {
          document.body.classList.add('android-keyboard-open');
        } else {
          document.body.classList.remove('android-keyboard-open');
        }
      };
      $inputElements.forEach((element) => {
        element.addEventListener('focus', () => handleAndroidKeyboardToggle('open'));
        element.addEventListener('blur', () => handleAndroidKeyboardToggle('close'));
      });
    }
  }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }
  
    if (/android/i.test(userAgent)) {
        return 'Android';
    }
  
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }
  
    return 'unknown';
  }
  