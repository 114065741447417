import React from 'react';
import PropTypes from 'prop-types';
import history from '../history';

const HeadPage = (props) => {
  const {
    pageTitle,
    pageDescription,
    hasBackButton,
    titleBgColor,
    customBackButton,
    mainHeading,
    boldSmallDescription,
  } = props;

  const handleBackClick = () => {
    history.goBack();
  };

  const modifier = titleBgColor ? `head-page--${titleBgColor}` : '';
  const mainHeadingModifier = mainHeading ? 'head-page__title--heading' : '';
  const descriptionModifier = boldSmallDescription ? 'small-text--bold' : '';
  let backButtonFunc = null;

  if (hasBackButton) {
    backButtonFunc = handleBackClick;
  }

  if (customBackButton) {
    backButtonFunc = customBackButton;
  }

  return (
    <div className={`head-page ${modifier}`}>
      {backButtonFunc && (
        <button className="head-page__back-btn" onClick={backButtonFunc}>
          <i className="fas fa-chevron-left"></i> back{' '}
        </button>
      )}
      {pageTitle && (
        <div className={`head-page__title ${mainHeadingModifier}`}>
          <h1 dangerouslySetInnerHTML={{ __html: pageTitle }}></h1>
        </div>
      )}
      {pageDescription && (
        <div className="head-page__description">
          <p className={`small-text ${descriptionModifier}`}>
            {pageDescription}
          </p>
        </div>
      )}
    </div>
  );
};

HeadPage.defaultProps = {
  pageTitle: null,
  pageDescription: null,
  hasBackButton: false,
  titleBgColor: '',
  mainHeading: false,
  customBackButton: null,
  boldSmallDescription: false,
};

HeadPage.propTypes = {
  pageTitle: PropTypes.string,
  mainHeading: PropTypes.bool,
  pageDescription: PropTypes.string,
  hasBackButton: PropTypes.bool,
  titleBgColor: PropTypes.string,
  customBackButton: PropTypes.func,
  boldSmallDescription: PropTypes.bool,
};

export default HeadPage;
