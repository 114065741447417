import * as types from '../actions/ActionTypes';

const initialState = {
  loading: false,
  profile: {},
  customerTypes: [],
  lbpLicenceClasses: []
};

export default function AccountsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ACCOUNT_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_ACCOUNT_DETAILS_COMPLETE:
      return {
        ...state,
        loading: false,
        ...action.accountDetails
      };
    case types.LOAD_STORE_DETAILS_BY_LOCATION:
      return {
        ...state,
        storeDetailsByLocation: action.storeDetailsByLocation
      };

    default:
      return state;
  }
}
