export const LOAD = 'LOAD';
export const UPDATE = 'UPDATE';
export const LOAD_DOCUMENTS_LIBRARY_CATEGORIES = 'document/library/categories/get';
export const LOAD_DOCUMENTS_LIBRARY = 'document/library/get';
export const LOAD_DOCUMENTS_LIBRARY_MEDIA = 'document/library/media/get';
export const UI_DOCUMENTS_LIBRARY_SEARCH_STATUS = 'document/library/search/status/get';
export const LOAD_DOCUMENTS_LIBRARY_SEARCH_START = 'document/library/search:loading';
export const LOAD_DOCUMENTS_LIBRARY_SEARCH_COMPLETE = 'document/library/search';
export const LOAD_DOCUMENTS_LIBRARY_SEARCH_CLEAR = 'document/library/search/clear';
export const LOAD_FAVOURITE_AND_RECENT_VIEWED = 'favourite/get';
export const POST_DOCUMENTS_LIBRARY_FAVOURIES = 'document/library/favourite/get';
export const POST_DOCUMENTS_LIBRARY_RECENTLY_VIEWED = 'document/library/recent/get';

export const LOAD_NEWS_POSTS_BY_USERS_START = 'news/posts/get:loading';
export const LOAD_NEWS_POSTS_BY_USERS_COMPLETE = 'news/posts/get';
export const LOAD_NEWS_INTERESTS_BY_USER_START = 'news/interests/get:loading';
export const LOAD_NEWS_INTERESTS_BY_USER_COMPLETE = 'news/interests/get';
export const POST_NEWS_INTERESTS_BY_USERS_START = 'news/interests/create:loading';
export const POST_NEWS_INTERESTS_BY_USERS_COMPLETE = 'news/interests/create';

export const LOAD_TRADE_CLUB_DETAILS_START = 'trade-club/details/get:loading';
export const LOAD_TRADE_CLUB_DETAILS_COMPLETE = 'trade-club/details/get';

export const LOAD_PRIORITY_CARD_DETAILS_START = 'priority-card/details/get:loading';
export const LOAD_PRIORITY_CARD_DETAILS_COMPLETE = 'priority-card/details/get';
export const POST_PRIORITY_CARD_DETAILS_START = 'priority-card/details/create:start';
export const POST_PRIORITY_CARD_DETAILS_COMPLETE = 'priority-card/details/create';

export const LOAD_DEALS_POSTS_BY_USERS_START = 'deals/get:loading';
export const LOAD_DEALS_POSTS_BY_USERS_COMPLETE= 'deals/get';

export const LOAD_LBP = 'lbp/get';
export const LOAD_LBP_START = 'lbp/get:loading';
export const LOAD_LBP_BASICS = 'lbp/basics/get';
export const LOAD_LBP_ACTIVITIES_LOG = 'lbp/activities/log/get';
export const LOAD_LBP_CODEWORDS_LOG = 'lbp/code-words/log/get';
export const LOAD_LBP_CODEWORDS = 'lbp/codewords/get';
export const POST_LBP_ACTIVITY_SUCCESS = 'lbp/activity/create';
export const POST_LBP_USER_PROFILE_SUCCESS = 'lbp/profile/create';
export const LOAD_LBP_LICENCE_CLASSES = 'lbp/licence-class/get';
export const POST_LBP_ADD_ACTIVITY_SUCCESS = 'lbp/activity/create';
export const LOAD_HOME_PAGE_START = 'page/home:loading';
export const LOAD_HOME_PAGE_COMPLETE = 'page/home';
export const LOAD_ACCOUNT_DETAILS_START = 'user/account/get:loading';
export const LOAD_ACCOUNT_DETAILS_COMPLETE = 'user/account/get';
export const LOAD_PROFILE_COMPLETE = 'user/profile/get';
export const UPDATE_STORE_DETAILS = 'user/store/update';
export const UPDATE_USER_DETAILS = 'user/update';
export const UPDATE_USER_LBP_DETAILS = 'user/lbp/update';
export const LOAD_STORE_DETAILS_BY_LOCATION = 'stores/location/get';
export const POST_PRIORITY_CARD_BY_USER = 'user/priority-card/create';
export const POST_CREATE_USER_ACCOUNT = 'user/account/create';
export const POST_CUSTOMER_TYPE = 'user/account/customer-type/update';
export const POST_STORE_SELECTED_BY_USER = 'user/account/store/update';
export const LOAD_STORE_DETAILS = 'store/get';
export const POST_USER_EMAIL_FOR_LOGIN = 'user/login/email';
export const AUTH_USER_LOGOUT = 'authentication/logout';
export const AUTH_USER_LOGGED_IN = 'authentication/login';
export const AUTH_USER_LOGIN_ERROR = 'authentication/login/error';
export const SIGN_UP_USER_STAGE_CHANGE = 'sign-up/stage';
export const SIGN_UP_USER_PROFILE = 'sign-up/profile';
export const SIGN_UP_CREATE_USER = 'sign-up/create';

export const SYSTEM_ERROR = 'system/error';
export const SYSTEM_ERROR_RESET = 'system/error/reset';