import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const FeaturedCard = (props) => {
  const { variant,
    heading,
    description,
    image,
    linkUrl
  } = props;

  const promotionalVariant = () => {

    return (
      <div className="featured-card__content">
        <div className="featured-card__heading z-index-one">
          <h3 className="yellow-highlight">{heading}</h3>
        </div>
        <div className="featured-card__description z-index-one">
          <p>{description}</p>
        </div>
      </div>
    );
  };

  const newsVariant = () => {

    return (
      <div className="featured-card__content">
        <div className="featured-card__heading z-index-one">
          <h4>{heading}</h4>
        </div>
        <div className="featured-card__description z-index-one">
          <p className="small-text">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <Link to={linkUrl} className="transform-none" >
      <div className={'featured-card ' + (variant === 'promotional' ? 'featured-card--promotional' : 'featured-card--news')}>
        <div className="featured-card__wrapper">
          <div className="featured-card__img -bg-image" style={{ backgroundImage: `url(${image})` }}></div>
          {variant === 'promotional' ? promotionalVariant() : newsVariant()}
        </div>
      </div>
    </Link>
  );
};

FeaturedCard.defaultProps = {
  variant: null,
  heading: null,
  description: null,
  image: null,
  linkUrl: null
};

FeaturedCard.propTypes = {
  variant: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  linkUrl: PropTypes.string
};

export default FeaturedCard;
