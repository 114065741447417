import * as types from './ActionTypes';
import {CreateUser} from '../api/CreateUser';
import {loadUserDetailsSuccess} from './AccountsActions';
import { GetUserDetails } from '../api/GetUserDetails';

export const userSignupStageUpdate = (status) => {
  return {
      type: types.SIGN_UP_USER_STAGE_CHANGE,
      status
  };
};

export const userUpdateSignupProfile = (profile) => {
  return {
      type: types.SIGN_UP_USER_PROFILE,
      profile
  };
};

export const userSignupCreate = () => async (dispatch, getState) => {
  const {signUp: {customerType, storeId}} = getState();

  const request = {
    StoreID: Number(storeId),
    CustomerType: Number(customerType),
  };
  
  await CreateUser(request);
  
  const profile = await GetUserDetails();
  dispatch(loadUserDetailsSuccess({profile}));
  dispatch(userSignupStageUpdate('complete'));
};