import React from 'react';
import PropTypes from 'prop-types';

const StickyButton = (props) => {
  const { text, btnDisabled } = props;
  return (
    <div className="form-button form-button--sticky">
      <button type="submit" className="btn btn--primary" disabled={btnDisabled}>
        {text}
      </button>
    </div>
  );
};

StickyButton.defaultProps = {
  text: null,
  btnDisabled: false,
};

StickyButton.propTypes = {
  text: PropTypes.string,
  btnDisabled: PropTypes.bool,
};

export default StickyButton;
