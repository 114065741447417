import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Form/Button';
import { Link } from 'react-router-dom';

const LbpActivityFormPreview = (props) => {

  const { Date, Description, Hours, KeyLearning, Title, Type, lbpActivityImageURL, handleLbpDiaryAddActivity, handleLbpGoHome } = props;

  return (
    <div className="form-preview horizontal-spacing">
      <div className="form-preview__bold" >
        <p className="small-text">Activity Type</p>
        <h4>{Type}</h4>
      </div>
      <div className="form-preview__bold" >
        <p className="small-text">Activity Title</p>
        <h4>{Title}</h4>
      </div>
      {Description && <div className="form-preview__bold">
        <p className="small-text">Activity Details</p>
        <p>{Description}</p>
      </div>}
      {KeyLearning && <div className="form-preview__bold">
        <p className="small-text">Key Learning</p>
        <p>{KeyLearning}</p>
      </div>}
      <div className="form-preview__bold">
        <p className="small-text">Date Completed</p>
        <h4>{Date}</h4>
      </div>
      {Hours !== '0' && <div className="form-preview__bold">
        <p className="small-text">Hours Spent</p>
        <h4>{Hours}</h4>
      </div>}
      {lbpActivityImageURL.length > 0 && <div className="form-preview__bold form-preview__img">
        <p className="small-text">Photos</p>
        {lbpActivityImageURL.map((imageSrc, index) =>
          <img key={index} src={imageSrc} alt={Title} />
        )}
      </div>}
        <Button type="button" text="Add Another Activity" handleSubmit={handleLbpDiaryAddActivity} />
        <Link to="/lbp-diary" className="transform-none" onClick={handleLbpGoHome}>Back to LBP Diary</Link>
    </div>
  );
};

LbpActivityFormPreview.propTypes = {
  Date: PropTypes.string,
  Description: PropTypes.string,
  Hours: PropTypes.string,
  KeyLearning: PropTypes.string,
  Title: PropTypes.string,
  Type: PropTypes.string,
  lbpActivityImageURL: PropTypes.array,
  handleLbpDiaryAddActivity: PropTypes.func,
  handleLbpGoHome: PropTypes.func
};

LbpActivityFormPreview.defaultProps = {
  Date: '',
  Description: '',
  Hours: '',
  KeyLearning: '',
  Title: '',
  Type: '',
  lbpActivityImageURL: [],
  handleLbpDiaryAddActivity: (()=>{}),
  handleLbpGoHome: (()=>{})
};

export default LbpActivityFormPreview;
