import {
  getAuthenticatedAxios,
  withErrorHandler,
} from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const GetActivityLogExportPdf = async () => {
  const url = buildRequestUrl('/api/users/lbp/activities/pdf');
  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().get(url, { responseType: 'blob' }),
  );

  return data;
};
