import { getAuthenticatedAxios, withErrorHandler } from './AuthenticatedAxiosProvider';
import { buildRequestUrl } from '../Config';

export const postPriorityCardNumber = async (cardNumber) => {
  const payload = { PriorityCardNumber: cardNumber };

  const { data } = await withErrorHandler(() =>
    getAuthenticatedAxios().post(
      buildRequestUrl('/api/users/user/post'),
      payload,
    ),
  );

  return data;  
};
